<template>
  <div class="flex flex-col">
    <div class="overflow-x-auto">
      <div class="py-3 inline-block min-w-full sm:px-6 lg:px-8">
        <div class="overflow-hidden">
          <table class="min-w-full border">
            <tbody>
              <tr class="border-b">
                <td colspan="2" class="text-sm text-black font-light px-6 py-4 whitespace-nowrap border-r">
                  場次名稱：{{report.Name}}
                </td>
                <td class="text-sm text-black font-light px-6 py-4 whitespace-nowrap">
                  場次時間：{{report.Date}}
                </td>
              </tr>
              <tr class="border-b">
                <td class="text-sm text-black font-light px-6 py-4 whitespace-nowrap border-r">
                  報名人數：{{report.Count}}
                </td>
                <td class="text-sm text-black font-light px-6 py-4 whitespace-nowrap border-r">
                  出席人數：{{report.AttendCount}}
                </td>
                <td class="text-sm text-black font-light px-6 py-4 whitespace-nowrap">
                  填寫人數：{{report.DoneCount}}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mt-5" v-if="report.Qnr.Questions.filter(e => e.Type == 4) && report.Qnr.Questions.filter(e => e.Type == 4).length > 0">
            <div class="text-xl my-2">滿意度調查</div>
            <table class="min-w-full mb-1 border">
              <thead class="border-b bg-gray-50">
                <tr>
                  <th scope="col" class="text-sm font-medium text-black px-6 py-4">
                    題目
                  </th>
                  <th scope="col" class="text-sm font-medium text-black px-6 py-4">
                    非常不滿意
                  </th>
                  <th scope="col" class="text-sm font-medium text-black px-6 py-4">
                    不滿意
                  </th>
                  <th scope="col" class="text-sm font-medium text-black px-6 py-4">
                    普通
                  </th>
                  <th scope="col" class="text-sm font-medium text-black px-6 py-4">
                    滿意
                  </th>
                  <th scope="col" class="text-sm font-medium text-black px-6 py-4">
                    非常滿意
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in report.Qnr.Questions.filter(e => e.Type == 4)" :key="item.Id"  class="border-b">
                  <td class="text-sm text-black font-light px-6 py-4 whitespace-nowrap border-r">
                    {{item.Title}}
                  </td>
                  <td class="text-sm text-black font-light px-6 py-4 whitespace-nowrap border-r">
                    {{report.List4.filter(e => e.QuestionId == item.Id && e.Answer == '1')?.length ?? 0}}
                  </td>
                  <td class="text-sm text-black font-light px-6 py-4 whitespace-nowrap border-r">
                    {{report.List4.filter(e => e.QuestionId == item.Id && e.Answer == '2')?.length ?? 0}}
                  </td>
                  <td class="text-sm text-black font-light px-6 py-4 whitespace-nowrap border-r">
                    {{report.List4.filter(e => e.QuestionId == item.Id && e.Answer == '3')?.length ?? 0}}
                  </td>
                  <td class="text-sm text-black font-light px-6 py-4 whitespace-nowrap border-r">
                    {{report.List4.filter(e => e.QuestionId == item.Id && e.Answer == '4')?.length ?? 0}}
                  </td>
                  <td class="text-sm text-black font-light px-6 py-4 whitespace-nowrap border-r">
                    {{report.List4.filter(e => e.QuestionId == item.Id && e.Answer == '5')?.length ?? 0}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="mt-5" v-if="report.Qnr.Questions.filter(e => e.Type == 1) && report.Qnr.Questions.filter(e => e.Type == 1).length > 0">
            <div class="text-xl my-2">單選題</div>
            <table v-for="item in report.Qnr.Questions.filter(e => e.Type == 1)" :key="item.Id"  class="min-w-full mb-1 border">
              <thead class="border-b bg-gray-50">
                <tr>
                  <th scope="col" :colspan="item.Options.length" class="text-sm font-medium text-black px-6 py-4">
                    {{item.Title}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="border-b">
                  <td v-for="option in item.Options" :key="option.Id" class="text-sm text-black font-light px-6 py-4 whitespace-nowrap border-r">
                    {{option.Title}}
                  </td>
                </tr>
                <tr class="border-b">
                  <td v-for="option in item.Options" :key="option.Id" class="text-sm text-black font-light px-6 py-4 whitespace-nowrap border-r">
                    {{report.List1.filter(e => e.QuestionId == item.Id && e.Answer == option.Id)?.length ?? 0}}
                    <div v-if="option.Added && (report.List1.filter(e => e.QuestionId == item.Id && e.Answer == option.Id)?.length ?? 0) > 0">
                      <button type="button" @click="() => { option.Question.HasChild = !option.Question.HasChild }" class="text-white text-xs mt-1 bg-blue-700 hover:bg-blue-800 focus:ring-4
                        focus:ring-blue-300 font-medium rounded-lg px-5 py-2.5 mr-2 mb-2  focus:outline-none">
                        顯示內容
                      </button>
                      <ul v-if="option.Question.HasChild" class="space-y-1 max-w-md list-disc list-inside text-black">
                        <li v-for="(other, index) in report.List1.filter(e => e.QuestionId == item.Id && e.Answer == option.Id)" :key="index">
                          {{other.OtherText}}
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-5" v-if="report.Qnr.Questions.filter(e => e.Type == 2) && report.Qnr.Questions.filter(e => e.Type == 2).length > 0">
            <div class="text-xl my-2">複選題</div>
            <table v-for="item in report.Qnr.Questions.filter(e => e.Type == 2)" :key="item.Id"  class="min-w-full mb-1 border">
              <thead class="border-b bg-gray-50">
                <tr>
                  <th scope="col" :colspan="item.Options.length" class="text-sm font-medium text-black px-6 py-4">
                    {{item.Title}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="border-b">
                  <td v-for="option in item.Options" :key="option.Id" class="text-sm text-black font-light px-6 py-4 whitespace-nowrap border-r">
                    {{option.Title}}
                  </td>
                </tr>
                <tr class="border-b">
                  <td v-for="option in item.Options" :key="option.Id" class="text-sm text-black font-light px-6 py-4 whitespace-nowrap border-r">
                    {{report.List2.filter(e => e.QuestionId == item.Id && e.Answer.includes(option.Id))?.length ?? 0}}
                    <div v-if="option.Added && (report.List2.filter(e => e.QuestionId == item.Id && e.Answer.includes(option.Id))?.length ?? 0) > 0">
                      <button type="button" @click="() => { option.Question.HasChild = !option.Question.HasChild }" class="text-white text-xs mt-1 bg-blue-700 hover:bg-blue-800 focus:ring-4
                        focus:ring-blue-300 font-medium rounded-lg px-5 py-2.5 mr-2 mb-2  focus:outline-none">
                        顯示內容
                      </button>
                      <ul v-if="option.Question.HasChild" class="space-y-1 max-w-md list-disc list-inside text-black">
                        <li v-for="(other, index) in report.List2.filter(e => e.QuestionId == item.Id && e.Answer.includes(option.Id))" :key="index">
                          {{other.OtherText}}
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-5" v-if="report.Qnr.Questions.filter(e => e.Type == 3) && report.Qnr.Questions.filter(e => e.Type == 3).length > 0">
            <div class="text-xl my-2">問答題</div>
            <table v-for="item in report.Qnr.Questions.filter(e => e.Type == 3)" :key="item.Id"  class="min-w-full mb-1 border">
              <thead class="border-b bg-gray-50">
                <tr>
                  <th scope="col" colspan="1" class="text-sm font-medium text-black px-6 py-4">
                    {{item.Title}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="answer in report.List3.filter(e => e.QuestionId == item.Id)" :key="answer.Id"  class="border-b">
                  <td class="text-sm text-black font-light px-6 py-4 whitespace-nowrap border-r">
                    {{answer.Answer}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType,  } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import { onMounted, watch } from 'vue'

export default defineComponent({
  components: {
    Grid,
  },
  props: {
    id: Number,
    visible: Boolean,
  },
  setup(props) {
    const model = CloudFun.current?.model
    const data = ref() as any;
    const session = ref() as any;
    const attendDetails = ref([]) as any;
    const report = reactive({
      Name: '',
      Count: 0,
      AttendCount: 0,
      Date: '',
      DoneCount: 0,
      Qnr: [],
      List1: [], //單選
      List2: [], //複選
      List3: [], //問答
      List4: [], //滿意度
    }) as any;
    // const grid = ref<any>();
    // const gridOptions: GridOptions = {
    //   title: '問題',
    //   canCreate: false,
    //   canUpdate: false,
    //   canDelete: false,
    //   canRead: false,
    //   multiselect: false,
    //   showFooter: true,      
    //   columns: [
    //     { field: 'Question.Title', title: '問卷題目', showOverflow: true, sortable: true, resizable: false },
    //     { field: 'Question.Type', title: '類型', showHeaderOverflow: true, showOverflow: true, sortable: true,
    //       formatter: ({ cellValue }) => {
    //         const item = model?.enums && Object.values(model?.enums.QuestionType).find((e) => e.Value === cellValue);
    //         return item ? item.Name : "";
    //       }
    //     },
    //     { field: 'AnswerText', title: '回答內容', showOverflow: true, sortable: true, resizable: false },   
    //     { field: 'OtherText', title: '其他說明', showOverflow: true, sortable: true, resizable: false },
    //   ],
    //   promises: { query: props.promises?.query, queryAll: props.promises?.queryAll, save: props.promises?.save },
    //   modalConfig: { width: "60%", height: "600", showFooter: true },
    // }

    // const formOptions: VxeFormProps = {
    //   titleWidth: 100,
    //   titleAlign: 'right',
    //   items: [
    //     {
    //       field: 'Question.Title',
    //       title: '問卷題目',
    //       span: 12,
    //       itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字' }, }
    //     },
    //     {
    //       field: 'AnswerText',
    //       title: '回答內容',
    //       span: 12,
    //       itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字' }, }
    //     },
    //     {
    //       field: 'OtherText',
    //       title: '其他說明',
    //       span: 12,
    //       itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字' }, }
    //     },
    //   ],
    //   rules:{
    //     Title: [{ type: 'string', required: true }],
    //   }
    // }

    const loadData = async () => {
      session.value = await model?.dispatch("session/find", props.id);
      console.log(session.value);

      var condition = new Condition().and("SessionId", Operator.Equal, props.id!.toString()).and("AttendMember.Attend.DataMode", Operator.Equal, 0)
      attendDetails.value = await model?.dispatch("attendDetail/query", { condition });
      console.log(attendDetails.value)

      condition = new Condition().and("AttendDetail.SessionId", Operator.Equal, props.id!.toString());
      var sortings = [{ column: "Question.Type", order: 1 },{ column: "Question.Ordinal", order: 0 },{ column: "CreatedTime", order: 1 }];
      data.value = await model?.dispatch("attendMemberQuestion/query", { condition, sortings });
      console.log(data.value);

      if(session.value && attendDetails.value && data.value) {
        //report
        report.Qnr = await model?.dispatch("qnr/find", session.value.QnrId);
        console.log(report.Qnr);
        report.Name = session.value.Name ?? '';
        report.Count = attendDetails.value.length ?? 0;
        report.Date = session.value.Date ? formatDate(session.value.Date, 'yyyy/MM/dd') : '';
        report.AttendCount = attendDetails.value.filter((e: any) => e.Attended).length ?? 0;
        var done = data.value.map((e: any) => e.AttendDetailId).filter((e: any, index: any, arr: any) => {
          return arr.indexOf(e) === index;
        });
        report.DoneCount = done.length;
        console.log(report.DoneCount);

        report.List1 = data.value.filter((e: any) => e.Question.Type == 1) //單選
        report.List2 = data.value.filter((e: any) => e.Question.Type == 2) //複選
        report.List3 = data.value.filter((e: any) => e.Question.Type == 3) //問答
        report.List4 = data.value.filter((e: any) => e.Question.Type == 4) //滿意度
      }
    }

    onMounted(async() => {
      await loadData();
    })

    watch(() => props.visible, async (value: any) => {
      if(value && props.id) {
        await loadData();
      }
    })

    // watch(() => props.id, async (value: any) => {
    //   console.log(value)
      
    // }, { immediate: true });

    return {
      data,
      report,
      // grid,
      // gridOptions,
      // formOptions,
    }
  },
  methods: {
    refresh() {
      (this.$refs.grid as any).refresh();
    },
    async onGridEdit (row: any, callback: any) {
      callback()
    },
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});
</script>
