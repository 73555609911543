<template>
  <fieldset class="border rounded m-2 px-2 pt-2">
    <div v-if="!readonly" class="flex items-center mx-1 mb-2">
      新增報名人
      <button v-if="table.data && table.data.length == 0" type="button" class="ml-auto" @click="table.insert">
        <FontAwesome icon="plus" class="w-5 h-5 text-yellow-500" />
      </button>
    </div>
    <vxe-table class="mb-2" ref="grid" :data="table.data" v-bind="gridOptions.props">
      <vxe-column field="CompanyName" title="公司名稱" sortable></vxe-column>
      <vxe-column field="Name" title="姓名" sortable></vxe-column>
      <!-- <vxe-column field="Department" title="部門" sortable></vxe-column> -->
      <vxe-column field="JobTitle" title="職稱" sortable></vxe-column>
      <vxe-column field="Email" title="Email" sortable></vxe-column>
      <vxe-column v-if="!readonly" title="操作" width="180">
        <template #default="{ row }">
          <div class="flex justify-content">
            <vxe-button @click="table.update(row)">編輯</vxe-button>
            <vxe-button v-if="!row.Id" @click="table.delete(row)">刪除</vxe-button>
          </div>
        </template>
      </vxe-column>
    </vxe-table>

    <vxe-modal v-model="table.isEditing" :title="table.selectedRow ? '編輯報名' : '新增報名'" width="70%" min-width="600"
      height="750" :loading="table.isLoading" resize destroy-on-close showFooter show-zoom="true">
      <template #default>
        <vxe-form ref="form" :rules="table.rules"  :data="table.formData" titleAlign="right" @submit="table.save">
          <vxe-form-item title="公司名稱" field="CompanyName" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input v-model="data.CompanyName" placeholder="請輸入公司名稱" clearable></vxe-input>
            </template>
          </vxe-form-item>
          <!-- <vxe-form-item title="公司英文名稱" field="EnCompanyName" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input v-model="data.EnCompanyName" placeholder="公司英文名稱" clearable></vxe-input>
            </template>
          </vxe-form-item> -->
          <vxe-form-item title="姓名" field="Name" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input v-model="data.Name" placeholder="請輸入姓名" clearable></vxe-input>
            </template>
          </vxe-form-item>
          <!-- <vxe-form-item title="英文姓名" field="EnName" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input v-model="data.EnName" placeholder="請輸入英文姓名" clearable></vxe-input>
            </template>
          </vxe-form-item> -->
          <vxe-form-item title="Email" field="Email" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input v-model="data.Email" @keyup="keyupEmail(data.Email)" placeholder="請輸入Email" :disabled="data.Id"></vxe-input>
             </template>
          </vxe-form-item>
          <vxe-form-item title="性別" field="Sex" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-select v-model="data.Sex" placeholder="請輸入性別">
                <vxe-option v-for="item in Object.values(model.enums.Sex).map(e => { return { label: e.Name, value: e.Value } })" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
              </vxe-select>
             </template>
          </vxe-form-item>
          <!-- <vxe-form-item title="部門" field="Department" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input v-model="data.Department" placeholder="請輸入部門" clearable></vxe-input>
             </template>
          </vxe-form-item> -->
          <vxe-form-item title="職稱" field="JobTitle" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input v-model="data.JobTitle" placeholder="請輸入職稱" clearable></vxe-input>
             </template>
          </vxe-form-item>
          <vxe-form-item title="聯絡電話" field="Phone" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input v-model="data.Phone" placeholder="請輸入聯絡電話" clearable></vxe-input>
             </template>
          </vxe-form-item>
          <!-- <vxe-form-item title="分機" field="Extention" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input v-model="data.Extention" placeholder="請輸入分機" clearable></vxe-input>
             </template>
          </vxe-form-item> -->
          <vxe-form-item title="郵遞區號" field="PostCode" :item-render="{}" span="8">
            <template #default="{ data }">
              <vxe-input v-model="data.PostCode" placeholder="請輸入郵遞區號" clearable></vxe-input>
             </template>
          </vxe-form-item>
          <vxe-form-item title="通訊地址" field="Address" :item-render="{}" span="16">
            <template #default="{ data }">
              <vxe-input v-model="data.Address" placeholder="請輸入通訊地址" clearable></vxe-input>
             </template>
          </vxe-form-item>
          <!-- <vxe-form-item title="得知活動管道" field="ActivityInfoChannel" :item-render="{}" span="24">
            <template #default="{ data }">
              <select-box v-bind="infoSelectorOptions" class="custom-vue-selector" v-model="data.ActivityInfoChannels" />
             </template>
          </vxe-form-item> -->
          <!-- <vxe-form-item title="可下載簡報檔案" field="Downloadable" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-select v-model="data.Downloadable" placeholder="可下載簡報檔案">
                <vxe-option v-for="item in downloadable" :key="item.value" :value="item.value" :label="`${item.label}`"></vxe-option>
              </vxe-select>
             </template>
          </vxe-form-item> -->
          <!-- <vxe-form-item title="調查表資訊" field="Survey" :item-render="{}" span="24">
            <template #default="{ data }">
              <vxe-input v-model="data.Survey" placeholder="請輸入調查表資訊" clearable></vxe-input>
             </template>
          </vxe-form-item> -->
          <!-- <vxe-form-item title="其他說明" field="OtherText" :item-render="{}" span="24">
            <template #default="{ data }">
              <vxe-input v-model="data.OtherText" placeholder="請輸入其他說明" clearable></vxe-input>
             </template>
          </vxe-form-item> -->
          <vxe-form-item field="AttendDetails" :item-render="{}" span="24">
            <template #default="{ data }">
              <SelectSession ref="speakerTable" @refresh="table.refresh" :activityId="aid" :data="data.AttendDetails" :rights="rights" :email="data.Email" />
             </template>
          </vxe-form-item>
          <!-- <vxe-form-item title="外部來源" field="Source" :item-render="{}" span="24">
            <template #default="{ data }">
              <vxe-textarea v-model="data.Source" placeholder="請輸入外部來源" clearable></vxe-textarea>
             </template>
          </vxe-form-item> -->
       </vxe-form>
      </template>
      <template #footer>
        <vxe-button type="button" status="primary" content="確認"
          @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })">
        </vxe-button>
      </template>
    </vxe-modal>
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps } from "vxe-table";
import SelectSession from "@/components/activity/SelectSession.vue";

interface AttendMember {
  Id: number;
  CompanyName: string;
  EnCompanyName: string;
  Department: string;
  EnDepartment: string;
  JobTitle: string;
  EnJobTitle: string;
  Name: string;
  Email: string;
  Extention: string;
  Phone: string;
  EnName: string;
  Sex: number;
  PostCode: string;
  Address: string;
  Survey: string;
  ActivityInfoChannel: number;
  OtherText: string;
  Downloadable: boolean;
  AttendDetails: AttendDetail[];
}

interface AttendDetail {
  SessionId: number;
  Session: { Id: number, Name: string };
  Number: number;
  MealType: number;
  Canceled: boolean;
  Attended: boolean;
  DiscountType: number;
  SalePrice: number;
  CouponType: number;
  DiscountAmount: number;
  Amount: number;
  CouponName: string;
}

export default defineComponent({
  name: "AttendMemberTable",
  components: {
    SelectBox,
    SelectSession
  },
  props: {
    data: {
      type: Array as PropType<AttendMember[]>,
      default: () => []
    },
    readonly: Boolean,
    aid: Number,
    sid: Number,
  },
  setup(props) {
    
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const rights = ref<any>([]);
    
    const gridOptions = {
      props: {
        sortConfig: {
          defaultSort: { field: 'CreatedTime', order: 'asc' }
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as AttendMember[],
      isLoading: false,
      isEditing: false,
      formData: { } as AttendMember,
      selectedRow: null as AttendMember | null,
      rules:{
        Name: [{ type: 'string', required: true }],
        CompanyName: [{ type: 'string', required: true }],
        Phone: [
          {
            type: "string",
            required: true,
            pattern: new RegExp(
              "^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$"
            ),
          },
        ],
        Email: [
          {
            required: true,
            type: 'string',
            pattern: new RegExp(
              "^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$"
            ),
          },
        ],
      },
      insert() {
        if(grid.value && grid.value.getTableData().fullData && grid.value.getTableData().fullData?.length == 0) {
          table.formData = { ActivityInfoChannel: 1, Sex: 0, Downloadable: false, AttendDetails: ([] as AttendDetail[]) } as AttendMember;
          table.selectedRow = null;
          table.isEditing = true;
        } else {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "已存在報名人",
          });
        }
      },
      update(row: AttendMember) {
        //console.log(row)
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;        
      },
      delete(row: AttendMember) {
        grid.value.remove(row);
      },
      async save() {
        table.isLoading = true;
        // var same = grid.value.getTableData().fullData.filter((e: any) => e.Email == table.formData.Email);
        // console.log(table.selectedRow?.Id, same, same.length)
        // if(same) {
        //   if((table.selectedRow?.Id && same.length > 1) || (!table.selectedRow?.Id && same.length > 0)) {
        //     CloudFun.send("error", {
        //       subject: "執行失敗",
        //       content: "已存在報名人！",
        //     });
        //     table.isLoading = false;
        //     return;
        //   }
        // }
        if(!table.formData.AttendDetails || table.formData.AttendDetails.length <= 0) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "請填寫報名場次！",
          });
          table.isLoading = false;
          return;
        }
        if (table.selectedRow) {
          Object.assign(table.selectedRow, table.formData)
        } else {
          await grid.value.insert(table.formData);
        }
        // grid.value.sort("Ordinal", 'asc');
        table.isEditing = false;
        table.isLoading = false;
      },
      refresh(value: AttendDetail[]) {
        table.formData!.AttendDetails = value;
        // console.log(table.formData!.AttendDetails)
        // console.log(table.data)
      }
    })

    watch(() => props.data, async(value) => {
      //const authorIds = value.filter(e => e.AuthorId).map(e => e.AuthorId);
      //const authors: AttendMember['Author'][] = await model?.dispatch("user/query", { condition: new Condition("Id", Operator.In, authorIds) });
      table.data = value.map(e => { 
        return e;
      });
      //console.log('attendMember', value);
    }, { immediate: true });

    const downloadable = [{ label: "是", value: true }, { label: "否", value: false }]

    const form = ref<any>();

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Account",
          title: "帳號",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "姓名",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("user/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'Name', order: 0 });
          return model!.dispatch("user/query", params) // eslint-disable-line
        }
      }
    };

    const infoSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ActivityInfoChannel).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          //if (params.condition) params.condition.and('Published', Operator.Equal, true).and('Type', Operator.Equal, 1)
          //else params.condition = new Condition('Published', Operator.Equal, true).and('Type', Operator.Equal, 1)
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ActivityInfoChannel).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const keyupEmail = async(email: string) => {
      rights.value = await model?.dispatch('member/findByEmail', email).then((data) => {
        return data;
      }).catch((error) =>{
        console.log(error)
      })
    }

    return {
      table,
      grid,
      gridOptions,
      form,
      userSelectorOptions,
      model,
      downloadable,
      rights,
      keyupEmail,
      infoSelectorOptions,
    }
  },
  methods: {
    getData() {
      // 避免 Author 帶過多關聯使的API維護Mapping
      return this.grid.getTableData().fullData.map((e: AttendMember) => {
        //delete e.Author;
        return e;
      });
    }
  }
})
</script>


<style scoped>
.custom-vue-selector :deep(.vue-tags-input.ti-disabled) {
  background-color: #f3f3f3;
}

.vxe-form--item.is--error .custom-vue-selector :deep(.vue-tags-input .ti-input) {
  border-color: #f56c6c !important;
}

.customer-required {
  content: "*";
  color: #f56c6c;
  font-family: Verdana, Arial, Tahoma;
  margin-right: 0.2em;
  font-weight: 400;
  vertical-align: middle;
}
</style>
