
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps } from "vxe-table";
import SelectSession from "@/components/activity/SelectSession.vue";

interface AttendMember {
  Id: number;
  CompanyName: string;
  EnCompanyName: string;
  Department: string;
  EnDepartment: string;
  JobTitle: string;
  EnJobTitle: string;
  Name: string;
  Email: string;
  Extention: string;
  Phone: string;
  EnName: string;
  Sex: number;
  PostCode: string;
  Address: string;
  Survey: string;
  ActivityInfoChannel: number;
  OtherText: string;
  Downloadable: boolean;
  AttendDetails: AttendDetail[];
}

interface AttendDetail {
  SessionId: number;
  Session: { Id: number, Name: string };
  Number: number;
  MealType: number;
  Canceled: boolean;
  Attended: boolean;
  DiscountType: number;
  SalePrice: number;
  CouponType: number;
  DiscountAmount: number;
  Amount: number;
  CouponName: string;
}

export default defineComponent({
  name: "AttendMemberTable",
  components: {
    SelectBox,
    SelectSession
  },
  props: {
    data: {
      type: Array as PropType<AttendMember[]>,
      default: () => []
    },
    readonly: Boolean,
    aid: Number,
    sid: Number,
  },
  setup(props) {
    
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const rights = ref<any>([]);
    
    const gridOptions = {
      props: {
        sortConfig: {
          defaultSort: { field: 'CreatedTime', order: 'asc' }
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as AttendMember[],
      isLoading: false,
      isEditing: false,
      formData: { } as AttendMember,
      selectedRow: null as AttendMember | null,
      rules:{
        Name: [{ type: 'string', required: true }],
        CompanyName: [{ type: 'string', required: true }],
        Phone: [
          {
            type: "string",
            required: true,
            pattern: new RegExp(
              "^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$"
            ),
          },
        ],
        Email: [
          {
            required: true,
            type: 'string',
            pattern: new RegExp(
              "^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$"
            ),
          },
        ],
      },
      insert() {
        if(grid.value && grid.value.getTableData().fullData && grid.value.getTableData().fullData?.length == 0) {
          table.formData = { ActivityInfoChannel: 1, Sex: 0, Downloadable: false, AttendDetails: ([] as AttendDetail[]) } as AttendMember;
          table.selectedRow = null;
          table.isEditing = true;
        } else {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "已存在報名人",
          });
        }
      },
      update(row: AttendMember) {
        //console.log(row)
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;        
      },
      delete(row: AttendMember) {
        grid.value.remove(row);
      },
      async save() {
        table.isLoading = true;
        // var same = grid.value.getTableData().fullData.filter((e: any) => e.Email == table.formData.Email);
        // console.log(table.selectedRow?.Id, same, same.length)
        // if(same) {
        //   if((table.selectedRow?.Id && same.length > 1) || (!table.selectedRow?.Id && same.length > 0)) {
        //     CloudFun.send("error", {
        //       subject: "執行失敗",
        //       content: "已存在報名人！",
        //     });
        //     table.isLoading = false;
        //     return;
        //   }
        // }
        if(!table.formData.AttendDetails || table.formData.AttendDetails.length <= 0) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "請填寫報名場次！",
          });
          table.isLoading = false;
          return;
        }
        if (table.selectedRow) {
          Object.assign(table.selectedRow, table.formData)
        } else {
          await grid.value.insert(table.formData);
        }
        // grid.value.sort("Ordinal", 'asc');
        table.isEditing = false;
        table.isLoading = false;
      },
      refresh(value: AttendDetail[]) {
        table.formData!.AttendDetails = value;
        // console.log(table.formData!.AttendDetails)
        // console.log(table.data)
      }
    })

    watch(() => props.data, async(value) => {
      //const authorIds = value.filter(e => e.AuthorId).map(e => e.AuthorId);
      //const authors: AttendMember['Author'][] = await model?.dispatch("user/query", { condition: new Condition("Id", Operator.In, authorIds) });
      table.data = value.map(e => { 
        return e;
      });
      //console.log('attendMember', value);
    }, { immediate: true });

    const downloadable = [{ label: "是", value: true }, { label: "否", value: false }]

    const form = ref<any>();

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Account",
          title: "帳號",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "姓名",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("user/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'Name', order: 0 });
          return model!.dispatch("user/query", params) // eslint-disable-line
        }
      }
    };

    const infoSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ActivityInfoChannel).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          //if (params.condition) params.condition.and('Published', Operator.Equal, true).and('Type', Operator.Equal, 1)
          //else params.condition = new Condition('Published', Operator.Equal, true).and('Type', Operator.Equal, 1)
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ActivityInfoChannel).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const keyupEmail = async(email: string) => {
      rights.value = await model?.dispatch('member/findByEmail', email).then((data) => {
        return data;
      }).catch((error) =>{
        console.log(error)
      })
    }

    return {
      table,
      grid,
      gridOptions,
      form,
      userSelectorOptions,
      model,
      downloadable,
      rights,
      keyupEmail,
      infoSelectorOptions,
    }
  },
  methods: {
    getData() {
      // 避免 Author 帶過多關聯使的API維護Mapping
      return this.grid.getTableData().fullData.map((e: AttendMember) => {
        //delete e.Author;
        return e;
      });
    }
  }
})
