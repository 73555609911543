<template>
  <Grid ref="grid" v-bind="gridOptions"
    @addNewRow="initData"
    @removeSelectedRows="hideBatchDropDown()"
    @edit="onGridEdit"
  >
  </Grid>
</template>
<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'

export default defineComponent({
  components: {
    Grid,
  },
  props: {
    sid: Number,
    promises: Object as PropType<{
      /** 非同步查詢 */
      query?: (params: {
        page: number;
        pageSize: number;
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<{ data: any[]; totalCount: number }>;
      /** 查詢所有資料 */
      queryAll?: (params?: {
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<any[]>;
      /** 儲存已變更資料 */
      save?: (params: {
        insertRows?: any[];
        updateRows?: any[];
        deleteRows?: any[];
      }) => Promise<void>;
    }>
  },
  setup(props) {
    const model = CloudFun.current?.model

    const grid = ref<any>();
    const gridOptions: GridOptions = {
      title: '問題',
      canCreate: false,
      canUpdate: false,
      canDelete: false,
      canRead: false,
      multiselect: false,
      showFooter: true,      
      columns: [
        { field: 'Question.Title', title: '問卷題目', showOverflow: true, sortable: true, resizable: false },
        { field: 'Question.Type', title: '類型', showHeaderOverflow: true, showOverflow: true, sortable: true,
          formatter: ({ cellValue }) => {
            const item = model?.enums && Object.values(model?.enums.QuestionType).find((e) => e.Value === cellValue);
            return item ? item.Name : "";
          }
        },
        { field: 'AnswerText', title: '回答內容', showOverflow: true, sortable: true, resizable: false },   
        { field: 'OtherText', title: '其他說明', showOverflow: true, sortable: true, resizable: false },
      ],
      promises: { query: props.promises?.query, queryAll: props.promises?.queryAll, save: props.promises?.save },
      modalConfig: { width: "60%", height: "600", showFooter: true },
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        {
          field: 'Question.Title',
          title: '問卷題目',
          span: 12,
          itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字' }, }
        },
        {
          field: 'AnswerText',
          title: '回答內容',
          span: 12,
          itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字' }, }
        },
        {
          field: 'OtherText',
          title: '其他說明',
          span: 12,
          itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字' }, }
        },
      ],
      rules:{
        Title: [{ type: 'string', required: true }],
      }
    }

    return {
      grid,
      gridOptions,
      formOptions,
    }
  },
  methods: {
    refresh() {
      (this.$refs.grid as any).refresh();
    },
    async onGridEdit (row: any, callback: any) {
      callback()
    },
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});
</script>
