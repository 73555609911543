<template>
  <div class="w-full flex xl:flex-row sm:flex-col xl:justify-end">
    <button v-if="grid && !grid.isEditing" class="button text-white bg-theme-1 shadow-md flex items-center mb-2 mr-2" @click="grid.addNewRow">
      <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
      新增
    </button>
    <!-- <button v-if="grid && !grid.isEditing" class="button text-white bg-theme-1 shadow-md flex items-center mb-2 mr-2" @click="importExcel">
      匯入報名資料
    </button>
    <button v-if="grid && !grid.isEditing" class="button text-white bg-theme-1 shadow-md flex items-center mb-2 mr-2" @click="downloadExcel">
      下載匯入範本
    </button> -->
    <button v-if="grid && !grid.isEditing" class="button text-white bg-red-500 shadow-md flex items-center mb-2" @click="ReHeadCount">
      重整資料
    </button>
  </div>
  <Grid ref="grid" v-bind="gridOptions"
    @addNewRow="initData"
    @removeSelectedRows="hideBatchDropDown()"
    @edit="onGridEdit"
  >
    <template #agenda="{ row }">
      <vxe-button type="button" content="查看" @click="agendaModal.show(row)"></vxe-button>
    </template>
    <template #survey="{ row }">
      <vxe-button type="button" content="查看" @click="surveyModal.show(row)"></vxe-button>
    </template>
    <template #discount="{ row }">
      <vxe-button type="button" content="查看" @click="discountModal.show(row)"></vxe-button>
    </template>
    <template #detail="{ row }">
      <vxe-button type="button" content="查看" @click="detailModal.show(row)"></vxe-button>
    </template>
    <template #modal="{ row, submit, reset }">
      <vxe-form
        class="ml-3"
        ref="sessionForm"
        v-bind="formOptions"
        :data="row"
        @reset="reset"
        @submit="submit">
        <template #column-qnr="{ data }">
          <select-box v-bind="selectorOptions" :dropdownWidth="500" v-model="data.QnrId" />
        </template>
      </vxe-form>
    </template>
    <template #modal-footer>
      <vxe-button type="submit" status="primary" content="確認" @click="$refs.sessionForm.validate((errMap) => { if (errMap === undefined) $refs.sessionForm.dispatchEvent('submit'); })"></vxe-button>
      <vxe-button type="reset" content="重置" @click="$refs.sessionForm.dispatchEvent('reset')"></vxe-button>
    </template>
  </Grid>
  <vxe-modal v-model="discountModal.visible" width="80%" title="場次折扣" show-zoom="true">
    <DiscountTable ref="discountGrid" v-bind="discountModal.gridOptions" :sid="discountModal.selectedRow.Id" />
  </vxe-modal>
  <vxe-modal v-model="agendaModal.visible" width="80%" height="700" title="活動議程" show-zoom="true">
    <AgendaTable ref="agendaGrid" v-bind="agendaModal.gridOptions" :sid="agendaModal.selectedRow.Id" />
  </vxe-modal>
  <vxe-modal resize v-model="surveyModal.visible" width="80%" title="媒合洽談意願調查表" height="700" show-zoom="true">
    <SurveyTable ref="surveyGrid" v-bind="surveyModal.gridOptions" @close="surveyModal.close" :modelValue="surveyModal.visible" :sid="surveyModal.selectedRow.Id" />
  </vxe-modal>
  <vxe-modal v-model="detailModal.visible" width="80%" height="700" title="場次報名資料" show-zoom="true">
    <SessionAttendTable ref="detailGrid" v-bind="detailModal.gridOptions" :sid="detailModal.selectedRow.Id" :haveQnr="(detailModal.selectedRow.QnrId ? true : false)" />
  </vxe-modal>
</template>
<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import VXETable, { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import DiscountTable from "@/components/activity/DiscountTable.vue";
import AgendaTable from "@/components/activity/AgendaTable.vue";
import SurveyTable from "@/components/activity/SurveyTable.vue";
import SessionAttendTable from "@/components/activity/SessionAttendTable.vue";
import { watch } from 'vue-demi';
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import { commafy } from "xe-utils";

export default defineComponent({
  components: {
    Grid,
    DiscountTable,
    AgendaTable,
    SurveyTable,
    SessionAttendTable,
    SelectBox,
  },
  props: {
    sid: Number,
    modelValue: Boolean,
    promises: Object as PropType<{
      /** 非同步查詢 */
      query?: (params: {
        page: number;
        pageSize: number;
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<{ data: any[]; totalCount: number }>;
      /** 查詢所有資料 */
      queryAll?: (params?: {
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<any[]>;
      /** 儲存已變更資料 */
      save?: (params: {
        insertRows?: any[];
        updateRows?: any[];
        deleteRows?: any[];
      }) => Promise<void>;
    }>
  },
  setup(props) {
    const model = CloudFun.current?.model

    const grid = ref<any>();
    const gridOptions: GridOptions = {
      title: '活動場次',
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      multiselect: false,
      showFooter: true,
      toolbarConfig: {
        refresh: true,
      },
      columns: [
        { field: 'Name', title: '場次名稱', showOverflow: true, sortable: true, resizable: true },
        { field: 'Date', title: '場次日期', showOverflow: true, sortable: true, resizable: true, formatter: ({ cellValue }) => formatDate(new Date(cellValue), 'yyyy/MM/dd') },
        { field: 'Price', title: '活動定價', showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: true, formatter: ({ cellValue }) => `${commafy(cellValue)}` },
        { field: 'HeadCount', title: '開放人數', showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: true, align: 'right', formatter: ({ cellValue }) => `${commafy(cellValue)}` },
        { field: 'AttendCount', title: '已報名人數', showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: true, align: 'right', formatter: ({ cellValue }) => `${commafy(cellValue)}` },
        { field: 'HasMeal', title: '是否供餐', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'center', formatter: ({ cellValue }) => cellValue ? '是' : '否' },       
        // { field: 'IsFull', title: '是否額滿', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => cellValue ? '是' : '否' },       
        // { field: 'Ordinal', title: '排序', showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: true },
        {
          title: '場次議程',
          width: '100',
          slots: { default: "agenda" }, align: 'center'
        },
        // {
        //   title: '媒合調查表',
        //   width: '100',
        //   slots: { default: "survey" }, align: 'center'
        // },
        {
          title: '報名資料',
          width: '100',
          slots: { default: "detail" }, align: 'center'
        },
        // {
        //   title: '場次折扣',
        //   width: '100',
        //   slots: { default: "discount" }
        // },
        // { field: 'Comment', title: '意見', showOverflow: true, sortable: true, resizable: false },
      ],
      promises: { query: props.promises?.query, queryAll: props.promises?.queryAll, save: props.promises?.save },
      modalConfig: { width: "50%", height: "450", showFooter: true },
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        { field: 'Name', title: '場次名稱', span: 12, itemRender: { name: '$input' } },
        {
          field: 'Date',
          title: '場次時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        {
          field: 'StartTime',
          title: '報到開始時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'time', labelFormat: 'HH:mm', placeholder: '請輸入時間', clearable: true }}
        },
        {
          field: 'EndTime',
          title: '報到結束時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'time', labelFormat: 'HH:mm', placeholder: '請輸入時間', clearable: true } }
        },
        {
          field: 'HeadCount',
          title: '開放人數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' }, }
        },
        {
          field: 'AttendCount',
          title: '已報名人數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' }, }
        },
        {
          field: 'HasMeal',
          title: '是否供餐',
          span: 12,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'IsFull',
          title: '是否額滿',
          span: 12,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'Price',
          title: '活動定價',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' }, }
        },
        {
          field: 'QnrId',
          title: '問卷資料',
          span: 12,
          slots: { default: "column-qnr" }
        },
        {
          field: 'RequireQnr',
          title: '是否必填問卷',
          span: 12,
          // titleWidth: 100,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },        
        {
          field: 'Ordinal',
          title: '排序',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' }, }
        },
        // {
        //   span: 24, align: "right", 
        //   itemRender: { name: '$buttons', children: [{ props: { type: 'submit', content: '確定', status: 'primary' } }, { props: { type: 'reset', content: '重置' } }] }
        // },
      ],
      rules:{
        Name: [{ type: 'string', required: true }],
        Date: [{ type: 'string', required: true }],
      }
    }

    const agendaGrid = ref<any>()
    const agendaModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition()
              .and("SessionId", Operator.Equal, agendaModal.selectedRow.Id.toString())
              .and(params.condition!);
            if (!params.sortings || params.sortings?.length === 0)
              params.sortings = [{ column: "Ordinal", order: 0 },{ column: "CreatedTime", order: 1 }];
            return model.dispatch("agenda/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('agenda/query') : undefined,
          save: model ? (params) => model.dispatch('agenda/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        agendaModal.visible = true;
        agendaModal.selectedRow = row;
        if (agendaGrid.value) agendaGrid.value.refresh();
      }
    })
    const surveyGrid = ref<any>()
    const surveyModal = reactive({
      visible: false,
      selectedRow: {} as any,
      // gridOptions: {
      //   promises: {
      //     query: model ? (params) => {
      //       params.condition = new Condition()
      //         .and("ActivityId", Operator.Equal, surveyModal.selectedRow.Id.toString())
      //         .and(params.condition!);
      //       if (!params.sortings || params.sortings?.length === 0)
      //         params.sortings = [{ column: "CreatedTime", order: 1 }];
      //       var selectedStatus = surveyGrid?.value.getSelectedStatus();
      //       if(selectedStatus != null) {
      //         params.condition .and("Status", Operator.Equal, selectedStatus)
      //       }
      //       return model.dispatch("attend/query", params);
      //     } : undefined,
      //     queryAll: model ? () => model.dispatch('attend/query') : undefined,
      //     save: model ? (params) => model.dispatch('attend/save', params) : undefined
      //   }
      // } as GridOptions,
      show(row: any) {
        surveyModal.visible = true;
        surveyModal.selectedRow = row;
        if (surveyGrid.value) surveyGrid.value.refresh();
      },
      close() {
        surveyModal.visible = false;
      }
    })
    const discountGrid = ref<any>()
    const discountModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition()
              .and("SessionId", Operator.Equal, discountModal.selectedRow.Id.toString())
              .and(params.condition!);
            // if (!params.sortings || params.sortings?.length === 0)
            //   params.sortings = [{ column: "CreatedTime", order: 1 }];
            return model.dispatch("sessionDiscount/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('sessionDiscount/query') : undefined,
          save: model ? (params) => model.dispatch('sessionDiscount/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        discountModal.visible = true;
        discountModal.selectedRow = row;
        if (discountGrid.value) discountGrid.value.refresh();
      }
    })

    const detailGrid = ref<any>()
    const detailModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition()
              .and("SessionId", Operator.Equal, detailModal.selectedRow.Id.toString())
              .and("AttendMember.Attend.DataMode", Operator.Equal, 0)
              .and(params.condition!);
            if (!params.sortings || params.sortings?.length === 0)
              params.sortings = [{ column: "AttendNumber", order: 0 },{ column: "AttendMember.CompanyName", order: 0 }];
              //params.sortings = [{ column: "AttendMember.CompanyName", order: 0 },{ column: "AttendNumber", order: 0 }];
            // if (!params.sortings || params.sortings?.length === 0)
            //   params.sortings = [{ column: "CreatedTime", order: 1 }];
            return model.dispatch("attendDetail/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('attendDetail/query') : undefined,
          save: model ? (params) => model.dispatch('attendDetail/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        detailModal.visible = true;
        detailModal.selectedRow = row;
        if (detailGrid.value) detailGrid.value.refresh();
      }
    })

    const initData = (row: any, callback: any) => {
      console.log(row)
      row.IsFull = false;
      row.HasMeal = false;
      row.RequireQnr = false;
      row.AttendCount = 0;
      row.Ordinal = 0;
      row.HeadCount = 0;
      row.Price = 0;
      row.ActivityId = props.sid;
      callback();
    }

    const ReHeadCount = () => {
      if(props.sid) {
        model!.dispatch('activity/reHeadCount', props.sid).then(() => {
          CloudFun.send("info", {
            subject: "執行成功",
            content: "已完成資料重整",
          }); 
          grid.value.refresh();
        }).catch((error) =>{
          console.log(error)
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error,
          });
        });
      } else {
        CloudFun.send("error", {
            subject: "執行失敗",
            content: "無法取得活動資料",
          });
      }
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Title",
      valueField: "Id",
      addIfNotExists: false,
      columns: [
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Introduction", title: "說明", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("qnr/find", value), // eslint-disable-line
        query: (params) => {
          params.condition = new Condition('Published', Operator.Equal, true)
          return model!.dispatch("qnr/query", params)
        }
      },
    };

    const importExcel = async () => {
      if(props.sid) {
        try {
          const { file } = await VXETable.readFile({ types: ['xlsx', 'xls'] });
          const formData = new FormData();
          formData.append("files", file);
          formData.append("id", (props.sid as any));
          await model?.dispatch('attendDetail/importExcel', { formData });
          await model!.dispatch('activity/reHeadCount', props.sid)
          CloudFun.send("info", {
            subject: "執行成功",
            content: `完成匯入`,
          });
          grid.value.refresh();
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const downloadExcel = async () => {
      window.open(`${process.env.VUE_APP_BACKEND_URL}/api/AttendDetail/DownloadExcel`)
    }

    return {
      grid,
      initData,
      gridOptions,
      formOptions,
      discountModal,
      discountGrid,
      agendaModal,
      agendaGrid,
      surveyGrid,
      surveyModal,
      detailModal,
      detailGrid,
      ReHeadCount,
      selectorOptions,
      importExcel,
      downloadExcel,
    }
  },
  methods: {
    refresh() {      
      (this.$refs.grid as any).refresh();
    },
    async onGridEdit (row: any, callback: any) {
      // const entity = row.Id ? await this.$model.dispatch('documentRecord/find', row.Id) : undefined
      // Object.assign(row, entity)
      // const statuses = (Object.values(this.$model.enums.DocumentStatus) as any[]);
      // row.BeforeStatusName = statuses.find(e => e.Value === row.BeforeStatus).Name;
      // row.AfterStatusName = statuses.find(e => e.Value === row.AfterStatus).Name;
      //   if (entity) {
      //     entity.Xml.Time = new Date(entity.Xml.Time)
      //   }
      callback()
    },
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});
</script>
