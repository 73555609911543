<template>
  <div class="w-full flex xl:flex-row sm:flex-col xl:justify-end">
    <button v-if="grid && !grid.isEditing" class="button text-white bg-theme-1 shadow-md flex items-center mb-2 mr-2" @click="grid.addNewRow">
      <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
      新增
    </button>
    <button v-if="grid && !grid.isEditing" class="button text-white bg-red-500 shadow-md flex items-center mb-2" @click="ReHeadCount">
      重整資料
    </button>
  </div>
  <div v-if="grid" class="flex flex-col mb-1 sm:flex-row sm:items-end xl:items-start">
    <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
      <vxe-select v-model="selectedStatus" placeholder="選擇狀態" @change="grid.refresh">
        <vxe-option class="py-3" :value="null" label="全部狀態"></vxe-option>
        <vxe-option class="py-3" v-for="(item) in Object.values(model?.enums?.PayStatus)" :key="item.Value" :value="item.Value" :label="item.Name"></vxe-option>
      </vxe-select>
    </div>
    <div class="xl:flex sm:mr-auto">
      <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
        <input type="search" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword" placeholder="請輸入關鍵字" />
      </div>
      <div class="mt-2 xl:mt-0">
        <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
          搜尋
        </button>
      </div>
    </div>
  </div>
  <Grid ref="grid" v-bind="gridOptions"
    @addNewRow="initData"
    @removeSelectedRows="hideBatchDropDown()"
    @edit="onGridEdit"
    @remove="remove"
  >
    <template #payment="{ row }">
      <vxe-button type="button" content="繳費資訊" @click="paymentModal.show(row)"></vxe-button>
    </template>
    <template #modal="{ row, submit, reset }">
      <vxe-form
        class="ml-3"
        ref="attendForm"
        v-bind="formOptions"
        :data="row"
        @reset="reset"
        @submit="submitData(row, submit)">
        <template #column-member-id="{ data }">
          <select-box v-bind="memberSelectorOptions" v-model="data.MemberId" />
        </template>
        <template #column-attend-members="{ data }">
          <AttendMemberTable
            ref="attendMemberGrid"
            :data="data.AttendMembers"
            v-bind="attendMemberModal.gridOptions"
            :sid="data.Id" :aid="data.ActivityId"
          />
        </template>
        <!-- <template #column-session-id>
          <select-box v-bind="sessionSelectorOptions" v-model="data.AttendDetail.SessionId" />
        </template> -->
      </vxe-form>
    </template>
    <template #modal-footer>
      <vxe-button type="submit" status="primary" content="確認" @click="$refs.attendForm.validate((errMap) => { if (errMap === undefined) $refs.attendForm.dispatchEvent('submit'); })"></vxe-button>
      <vxe-button type="reset" content="重置" @click="$refs.attendForm.dispatchEvent('reset')"></vxe-button>
    </template>
  </Grid>
  <vxe-modal v-model="paymentModal.visible" width="80%" height="700" title="繳費資訊" show-zoom="true">
      <PaymentTable ref="paymentGrid" :modelValue="paymentModal.visible" @close="() => (paymentModal.visible = false)" :sid="paymentModal.selectedRow.Id" />
  </vxe-modal>
</template>
<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import AttendMemberTable from "@/components/activity/AttendMemberTable.vue";
import PaymentTable from "@/components/activity/PaymentTable.vue";
import { watch } from 'vue-demi';
import { commafy } from "xe-utils";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    AttendMemberTable,
    PaymentTable,
  },
  props: {
    sid: Number,
    modelValue: Boolean,
    promises: Object as PropType<{
      /** 非同步查詢 */
      query?: (params: {
        page: number;
        pageSize: number;
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<{ data: any[]; totalCount: number }>;
      /** 查詢所有資料 */
      queryAll?: (params?: {
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<any[]>;
      /** 儲存已變更資料 */
      save?: (params: {
        insertRows?: any[];
        updateRows?: any[];
        deleteRows?: any[];
      }) => Promise<void>;
    }>
  },
  setup(props) {
    const model = CloudFun.current?.model

    const grid = ref<any>();
    const gridOptions: GridOptions = {
      title: '報名資訊',
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      multiselect: false,
      showFooter: true,
      toolbarConfig: {
        refresh: true,
      },
      columns: [
        { field: 'Member.Person.Name', title: '姓名', showOverflow: true, sortable: true, resizable: false, formatter: ({ row, cellValue }) => cellValue ?? row.Member?.Organization?.Name },
        {
        field: 'Status',
        title: '狀態',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        formatter: ({ cellValue }) => {
          const item = model?.enums && Object.values(model?.enums.PayStatus).find((e) => e.Value === cellValue);
          return item ? item.Name : "";
        }
        },
        { field: 'Amount', title: '總額', showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: false, align: 'right', formatter: ({ cellValue }) => `${commafy(cellValue)}` },
        {
          title: '繳費資訊',
          width: '150',
          slots: { default: "payment" }, align: 'center'
        },
      ],
      promises: { query: props.promises?.query, queryAll: props.promises?.queryAll, save: props.promises?.save },
      modalConfig: { width: "80%", height: "800", showFooter: true },
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        {
          field: 'MemberId',
          title: '會員名稱',
          span: 12,
          slots: { default: "column-member-id" }
        },
        {
          field: 'Status',
          title: '付款狀態',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.PayStatus).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'InvoiceType',
          title: '發票資訊',
          span: 12,
          itemRender: { name: "$select", options: [{ label: "二聯式", value: "0" }, { label: "三聯式", value: "1" }], props: { type: 'text', placeholder: '請輸入文字', disabled: 'true' } }
          //itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'InvoiceNo',
          title: '統一編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'InvoiceTitle',
          title: '發票抬頭',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'PostCode',
          title: '郵遞區號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Address',
          title: '發票地址',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'SaleAmount',
          title: '定價總額',
          span: 12,
          titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' }, }
        },
        {
          field: 'DiscountAmount',
          title: '折扣總額',
          span: 12,
          titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' }, }
        },
        {
          field: 'Amount',
          title: '總額',
          span: 12,
          titleWidth: 80,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' }, }
        },
        {
          field: 'Remark',
          title: '備註',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true } }
        },
        {
          field: 'AttendMembers',
          span: 24,
          slots: { default: "column-attend-members" }
        },

      ],
      rules:{
        MemberId: [{ required: true }],
        Status: [{ required: true }],
        InvoiceNo: [{ type: 'string', max: 8 }],
      }
    }
    

    const memberSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      disabled: false,
      columns: [
        {
          field: "Account",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ row, cellValue }) => cellValue ?? row.Organization?.Name,
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("member/find", value), // eslint-disable-line
        query: params => model!.dispatch("member/options", params) // eslint-disable-line
      }
    };

    const sessionSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("session/find", value), // eslint-disable-line
        query: params => model!.dispatch("session/query", params) // eslint-disable-line
      }
    };

    const attendMemberGrid = ref<any>()
    const attendMemberModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            // params.condition = new Condition()
            //   .and("ActivityId", Operator.Equal, attendMemberModal.selectedRow.Id.toString())
            //   .and(params.condition!);
            if (!params.sortings || params.sortings?.length === 0)
              params.sortings = [{ column: "CreatedTime", order: 1 }];
            return model.dispatch("attendMember/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('attendMember/query') : undefined,
          save: model ? (params) => model.dispatch('attendMember/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        attendMemberModal.visible = true;
        attendMemberModal.selectedRow = row;
        console.log(row)
        if (attendMemberGrid.value) attendMemberGrid.value.refresh();
      }
    })

    const paymentGrid = ref<any>()
    const paymentModal = reactive({
      visible: false,
      selectedRow: {} as any,
      show(row: any) {
        paymentModal.visible = true;
        paymentModal.selectedRow = row;
        console.log(row)
      }
    })

    const initData = (row: any, callback: any) => {
      row.MemberId = null;
      row.DiscountAmount = 0;
      row.Status = 1;
      row.Amount = 0;
      row.SaleAmount = 0;
      row.ActivityId = props.sid;
      memberSelectorOptions.disabled = false;
      //console.log(memberSelectorOptions.disabled)
      // row.AttendMembers = [{        
      // }]
      callback();
    }

    const distinct = (value: any, index: any, self: any) => {
      return self.indexOf(value) === index;
    }

    const submitData = (row: any, callback: any) => {      
      row.AttendMembers = attendMemberGrid.value.getData(); 
      //console.log(row.AttendMembers)
      var repeat = row.AttendMembers.filter(function(element: any, index: any, arr: any){
          return arr.map((e: any) => e.Email).indexOf(element.Email) !== index;
      });
      //console.log(repeat);
      if(repeat.length > 0) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: "存在相同報名資料！"+ repeat.map((e: any) => e.Email).filter(distinct).join(','),
        });
        return;
      }
      callback();
    }

    const remove = async(row: any, callback: any) => {
      if(row && row.Id) {
        row.DataMode = 2;
        await model!.dispatch('attend/update', row).then((data) => {
          //console.log(data);
          grid.value.refresh();
        }).catch((error) =>{
          console.log(error)
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error,
          });
        });
      }
      //callback();
    }

    const ReHeadCount = () => {
      if(props.sid) {
        model!.dispatch('activity/reHeadCount', props.sid).then(() => {
          CloudFun.send("info", {
            subject: "執行成功",
            content: "已完成資料重整",
          }); 
          grid.value.refresh();
        }).catch((error) =>{
          console.log(error)
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error,
          });
        });
      } else {
        CloudFun.send("error", {
            subject: "執行失敗",
            content: "無法取得活動資料",
          });
      }
    }

    const selectedStatus = ref<any>(null);

    return {
      model,
      grid,
      initData,
      submitData,
      gridOptions,
      formOptions,
      memberSelectorOptions,
      sessionSelectorOptions,
      attendMemberGrid,
      attendMemberModal,
      paymentGrid,
      paymentModal,
      remove,
      ReHeadCount,
      selectedStatus,
    }
  },
  methods: {
    refresh() {
      this.selectedStatus = null;
      (this.$refs.grid as any).keyword = null;
      (this.$refs.grid as any).refresh();
    },
    getSelectedStatus() {
      return this.selectedStatus;
    },
    async onGridEdit (row: any, callback: any) {
      const entity = row.Id ? await this.$model.dispatch('attend/find', row.Id) : undefined
      Object.assign(row, entity)
      if(row.Id) this.memberSelectorOptions.disabled = true;
      callback()
    },
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});
</script>
