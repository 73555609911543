
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType, onMounted } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import SurveyDetailTable from "@/components/activity/SurveyDetailTable.vue";
import AgendaFileUpload from "@/components/activity/AgendaFileUpload.vue";
import { watch } from 'vue-demi';

export default defineComponent({
  components: {
    Grid,
    SurveyDetailTable,
    AgendaFileUpload,
  },
  props: {
    sid: Number,
    modelValue: Boolean,
    promises: Object as PropType<{
      /** 非同步查詢 */
      query?: (params: {
        page: number;
        pageSize: number;
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<{ data: any[]; totalCount: number }>;
      /** 查詢所有資料 */
      queryAll?: (params?: {
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<any[]>;
      /** 儲存已變更資料 */
      save?: (params: {
        insertRows?: any[];
        updateRows?: any[];
        deleteRows?: any[];
      }) => Promise<void>;
    }>
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model
    const surveyDetailTable = ref<any>();
    const grid = ref<any>();
    const data = ref<any>();
    const displaySpeaker = (item: any) => { 
      const { cellValue } = item;
      return cellValue && cellValue.length ? cellValue.sort((a: any, b: any) => a.Ordinal - b.Ordinal).map((e: any) => e.Speaker?.Name) : "" 
    }

    const gridOptions: GridOptions = {
      title: '媒合洽談調查表',
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      multiselect: false,
      showFooter: true,      
      columns: [
        { field: 'Name', title: '名稱', showOverflow: true, sortable: true, resizable: false },
        { field: 'StartTime', title: '議程開始時間', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => cellValue?.substring(0, 5) },
        { field: 'EndTime', title: '議程結束時間', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => cellValue?.substring(0, 5) },
        { field: 'Speakers', title: '演講人', showOverflow: true, sortable: true, resizable: false, formatter: displaySpeaker },
        { field: 'Ordinal', title: '排序', showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: false },
      ],
      promises: { query: props.promises?.query, queryAll: props.promises?.queryAll, save: props.promises?.save },
      modalConfig: { width: "50%", height: "500", showFooter: true },
    }

    const changeType = (data: any) => {       
      if(data.IsCheckbox == false) data.Maximum = 1;
      const item = formOptions!.items![3];
      if(item) item.itemRender!.props!.disabled = data.IsCheckbox == false ? true : false;
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        {
          field: 'Title',
          title: '標題',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字' }, }
        },
        {
          field: 'Subject',
          title: '主旨',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字' }, }
        },
        { 
          field: "IsCheckbox",
          title: "是否複選",
          span: 12,
          itemRender: { name: "$select", options: [{ label: "是", value: true }, { label: "否", value: false }], events: { change: (e: any) => changeType(e.data) } } 
        },
        {
          field: 'Maximum',
          title: '最多可選項目數量',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', min: 0, placeholder: '請輸入數字' }, }
        },
        {
          field: 'Notice',
          title: '填寫附註',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字' }, }
        },
        {
          field: 'Additional',
          title: '次主旨',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字' }, }
        },
        {
          field: 'AdditionalOption',
          title: '次選項',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字' }, }
        },
        {
          field: 'Remark',
          title: '備註',
          span: 24,
          itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '備註' } }
        },     
        {
          span: 24,
          slots: { default: 'column-detail' }
        },
        // {
        //   span: 24,
        //   slots: { default: 'file' }
        // },
        {
          span: 24, align: "right", 
          itemRender: { name: '$buttons', children: [{ props: { type: 'submit', content: '確定', status: 'primary' } }, { props: { type: 'reset', content: '重置' } }] }
        },
      ],
      rules:{
        Title: [{ type: 'string', required: true }],
        IsCheckbox: [{ required: true }],
      }
    }

    const fileGrid = ref<any>()
    const fileModal = reactive({
      visible: false,
      selectedRow: {} as any,
      uploadOptions: {
        promises: {
          async query() {
            var condition = new Condition("AgendaId", Operator.Equal, fileModal.selectedRow.Id.toString());
            const sortings = [{ column: "CreatedTime", order: 1 }];
            return model?.dispatch('files/query', {
              condition: condition,
              sortings: sortings,
            });
          },
          insert(formData: FormData) {
            return model?.dispatch('files/insert', { formData, mode: 'other', entityId: 'Agenda_' + fileModal.selectedRow.Id, fileType: 0 });
          },
          delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      show(row: any) {
        //fileModal.visible = true;
        fileModal.selectedRow = row;
        if (fileGrid.value) fileGrid.value.refresh();
      }
    })

    onMounted(async()=> {
      data.value = await findSurvey(props.sid);
      changeType(data.value)
      //console.log(data.value)
    })

    watch(() => props.modelValue, async (val) => {
      if(val) {
        data.value = await findSurvey(props.sid);
        changeType(data.value)
        //console.log(data.value)
      }
    })

    const submitSurvey = async() => {
      data.value.SessionId = props.sid;
      if(data.value.Id) {
        data.value = await model!.dispatch('survey/update', data.value);
      } else {
        data.value = await model!.dispatch('survey/insert', data.value);
      }
      //console.log(data.value);
      emit('close');
    }

    const findSurvey = (id: any) => {
      var params = {
        condition: new Condition('SessionId', Operator.Equal, id.toString()),
        page: 1,
        pageSize: 1
      };
      return model?.dispatch('survey/query', params).then((data) => {
        return data?.data?.[0] ?? { IsCheckbox: false, Maximum: 0, Details:[] };
      }).catch((error) => {
        console.log(error)
      })
    }

    const refresh = async() => {
      console.log('refresh')
      data.value = await findSurvey(props.sid);
    }    

    return {
      grid,
      gridOptions,
      formOptions,
      surveyDetailTable,
      fileGrid,
      fileModal,
      data,
      submitSurvey,
      refresh,
    }
  },
  methods: {
    // refresh() {
    //   (this.$refs.grid as any).refresh();
    // },
    async onGridEdit (row: any, callback: any) {
      const entity = row.Id ? await this.$model.dispatch('agenda/find', row.Id) : undefined
      this.fileModal.show(row)
      Object.assign(row, entity)
      callback()
    },
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});
