
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps } from "vxe-table";

interface AttendDetail {
  SessionId: number;
  Session: { Id: number, Name: string };
  Number: number;
  MealType: number;
  Canceled: boolean;
  Attended: boolean;
  //DiscountType: number;
  SalePrice: number;
  //CouponType: number;
  DiscountAmount: number;
  Amount: number;
  // CouponName: string;
  // CouponCode: string;
  CouponId: any;
  CouponSetId: any;
}

export default defineComponent({
  name: "SelectSession",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<AttendDetail[]>,
      default: () => []
    },
    activityId: Number,
    readonly: Boolean,
    email: String,
    rights: [],
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const current = ref<any>();
    const rights = ref<any>([]);
    const gridOptions = {
      props: {
        sortConfig: {
          defaultSort: { field: 'Ordinal', order: 'asc' }
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as AttendDetail[],
      isLoading: false,
      isEditing: false,
      formData: { Attended: false, Canceled: false, MealType: 0, DiscountAmount: 0,
                  Amount: 0, SalePrice: 0, } as AttendDetail,
      selectedRow: null as AttendDetail | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: AttendDetail) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: AttendDetail) {
        grid.value.remove(row);
        emit('refresh', grid.value.getTableData().fullData);
      },
      async save() {
        var exist = grid.value.getTableData().fullData.some((e: any) => e.SessionId == table.formData.SessionId)
        if(exist) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "已報名該場次！",
          });
          return;
        }
        if(table.formData.CouponSetId && table.formData.CouponId) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "折扣優惠僅能擇一使用！",
          });
          return;
        }
        if (table.formData.SessionId) {
          const temp = await model?.dispatch("session/find", table.formData.SessionId)
          table.formData.Session = { Id: temp.Id, Name: temp.Name };
        }
        await grid.value.insert(table.formData);
        console.log(grid.value.getTableData().fullData)
        emit('refresh', grid.value.getTableData().fullData);
      },
      rules: {
        Attended: [{ required: true }],
        Canceled: [{ required: true }],
        SessionId: [{ required: true }],
      },
      reset() {        
        table.formData.Attended = false
        table.formData.Canceled = false
        table.formData.MealType = 0
        //table.formData.CouponType = 0
        table.formData.DiscountAmount = 0
        table.formData.Amount = 0
        //table.formData.DiscountType = 0
        table.formData.SalePrice = 0
        //table.formData.CouponCode = ""
        //table.formData.CouponName = ""
      },
      reloadCoupon() {
        if (couponSelectBox.value && couponSelectBox.value.grid.refresh) {
          couponSelectBox.value.grid.refresh();
        }
      },
      reloadCouponSet() {
        if (couponSetSelectBox.value && couponSetSelectBox.value.grid.refresh) {
          couponSetSelectBox.value.grid.refresh();
        }
      }
    })

    watch(() => table.formData.SessionId, async(value) => {
      table.reset()
      if(value) {
        const temp = await model?.dispatch("session/find", table.formData.SessionId)
        table.formData.SalePrice = temp?.Price
        table.formData.Amount = temp?.Price
        current.value = temp;
        console.log(current.value)
      } else {
        table.formData.CouponId = null;
        table.formData.CouponSetId = null;
      }
    }, { immediate: true });

    //折扣計算
    const changeDiscount = async(action: string, id: any) => {      
      table.formData.SalePrice = current.value?.Price;
      table.formData.Amount = current.value?.Price;
      table.formData.DiscountAmount = 0;
      if(id) {
        const temp = await model?.dispatch(action, id)
        if(temp) {
          //console.log('couponSet',temp)
          table.formData.DiscountAmount = table.formData.SalePrice - Math.round(table.formData.SalePrice * temp.Discount)
          table.formData.Amount = Math.round(table.formData.SalePrice * temp.Discount)
        }
      }
    }

    watch(() => table.formData.CouponId, async(value) => {
      if(table.formData.SessionId) changeDiscount("coupon/find", value);
      if(value) table.formData.CouponSetId = null;
    }, { immediate: true });

    watch(() => table.formData.CouponSetId, async(value) => {
      if(table.formData.SessionId) changeDiscount("couponSet/find", value);
      if(value) table.formData.CouponId = null;
    }, { immediate: true });

    

    // watch(() => table.formData.DiscountType, async(value) => {      
    //   const discount = current.value?.SessionDiscounts?.find((e: any) => e.DiscountType == value)
    //   console.log(discount);
    //   if(discount && discount.Discount) {
    //     //table.formData.CouponCode = `${current.value?.Name} ${(discount.Discount * 100).toString().replace('0', '')}折`;
    //     table.formData.DiscountAmount = table.formData.SalePrice - Math.round(table.formData.SalePrice * discount.Discount)
    //     table.formData.Amount = Math.round(table.formData.SalePrice * discount.Discount)
    //   } else {
    //     //table.formData.CouponCode = "";
    //     table.formData.DiscountAmount = 0;
    //     table.formData.Amount = current.value?.Price;
    //   }
    // }, { immediate: true });
    

    watch(() => props.data, async(value) => {
      console.log('details',value)
      const Sessions: any['Session'][] = await model?.dispatch("session/query", { condition: new Condition("ActivityId", Operator.Equal, props.activityId) });
      table.data = value.map(e => { 
        const session = Sessions.find(a => a && a.Id === e.SessionId)
        if(session) e.Session = { Id: session!.Id, Name: session!.Name };
        return e;
      });
        
      //table.data = value;
    }, { immediate: true });

    watch(() => props.rights, async(value: any) => {
      console.log('rights', value)
      if(value && value.length > 0) {
        rights.value = value;
      } else {
        rights.value = [];
      }        
    }, { immediate: true });

    const TF = [{ label: "是", value: true }, { label: "否", value: false }]

    const form = ref<any>();

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,          
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("session/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition().and("ActivityId", Operator.Equal, props.activityId)
          return model!.dispatch("session/query", params)
        }
      }
    };

    const couponSelectBox = ref<any>();
    const couponSelectorOptions: SelectBoxOptions = {      
      rowId: "Id",
      transfer: true,
      placeholder: "選擇類別",
      textField: "Name",
      valueField: "Id",
      multiselect: false,
      formatText: (row) => row.CouponSet?.Category?.Name ? `${row.CouponSet.Category.Name}-${row.Code}(${row.Discount})` : row,
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ row, cellValue }) => row.CouponSet?.Category?.Name ? `${row.CouponSet.Category.Name}-${row.Code}(${row.Discount})` : cellValue,
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("coupon/find", value), // eslint-disable-line
        query: params => {
          if(table.formData.SessionId && props.email) {
            //console.log(table.formData.SessionId, props.email,'sessionid')
            params.condition = new Condition().and("SessionId", Operator.Equal, table.formData.SessionId.toString()).and("Email", Operator.Equal, props.email).and("Status", Operator.Equal, 0)
            
            var start = new Condition().and("StartDate", Operator.IsNull).or("StartDate", Operator.LessThanOrEqual, new Date());            
            var end = new Condition().and("EndDate", Operator.IsNull).or("EndDate", Operator.GreaterThanOrEqual, new Date());

            params.condition.and(start).and(end);

            return model!.dispatch("coupon/query", params)
          } else {
            CloudFun.send("error", {
              subject: "執行失敗",
              content: "請輸入Email與場次資料",
            });
            return new Promise((resolve) => resolve({ data:[], totalCount:0 }));
          }
        }
      }
    };

    const couponSetSelectBox = ref<any>();
    const couponSetSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      multiselect: false,
      formatText: (row) => row.Category?.Name ? `${row.Category.Name}(${row.Discount})` : row,
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ row, cellValue }) => row.Category?.Name ? `${row.Category.Name}(${row.Discount})` : cellValue,
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("couponSet/find", value), // eslint-disable-line
        query: params => {
          if(table.formData.SessionId) {
            params.condition = new Condition().and("SessionId", Operator.Equal, table.formData.SessionId.toString()).and("Category.HasCode", Operator.Equal, false)
            
            params.condition.and("Category.RightsId", Operator.In, rights.value); // rightsId
            
            var start = new Condition().and("StartDate", Operator.IsNull).or("StartDate", Operator.LessThanOrEqual, new Date());            
            var end = new Condition().and("EndDate", Operator.IsNull).or("EndDate", Operator.GreaterThanOrEqual, new Date());

            params.condition.and(start).and(end);

            return model!.dispatch("couponSet/query", params)
          } else {
            CloudFun.send("error", {
              subject: "執行失敗",
              content: "請輸入場次資料",
            });
            return new Promise((resolve) => resolve({ data:[], totalCount:0 }));
          }
        }
      }
    };

    return {
      table,
      grid,
      gridOptions,
      form,
      userSelectorOptions,
      couponSelectorOptions,
      couponSetSelectorOptions,
      model,
      TF,
      couponSelectBox,
      couponSetSelectBox,
      props,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
