<template>
  <fieldset class="m-2 px-2 pt-2">
    <vxe-form class="" ref="form" :data="table.formData" :rules="table.rules" titleAlign="right" @submit="table.save">
      <vxe-form-item title="ID" field="Id" :item-render="{}" span="12">
        <template #default="{ data }">
          <vxe-input v-model="data.Id" type='text' placeholder="" disabled clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="支付方式" field="Type" :item-render="{}" span="12">
        <template #default="{ data }">
          <vxe-select v-model="data.Type" placeholder="請選擇支付方式" clearable>
            <vxe-option v-for="(item) in Object.values(model?.enums?.PaymentType)" :key="item.Value" :value="item.Value" :label="item.Name"></vxe-option>
          </vxe-select>
        </template>
      </vxe-form-item>
      <vxe-form-item title="繳費時間" field="Time" :item-render="{}" span="12">
        <template #default="{ data }">
          <vxe-input v-model="data.Time" type='date' labelFormat='yyyy/MM/dd HH:mm:ss' placeholder="請輸入日期" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="繳費期限" field="ExpriyTime" :item-render="{}" span="12">
        <template #default="{ data }">
          <vxe-input v-model="data.ExpriyTime" type='date' labelFormat='yyyy/MM/dd HH:mm:ss' placeholder="請輸入日期" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="交易日期" field="TransactionDate" :item-render="{}" span="12">
        <template #default="{ data }">
          <vxe-input v-model="data.TransactionDate" type='date' labelFormat='yyyy/MM/dd' placeholder="請輸入日期" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="收單行識別碼" field="AcquierId" :item-render="{}" span="12">
        <template #default="{ data }">
          <vxe-input v-model="data.AcquierId" type='text' placeholder="請輸入文字" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="卡/帳號" field="Pan" :item-render="{}" span="12">
        <template #default="{ data }">
          <vxe-input v-model="data.Pan" type='text' placeholder="請輸入文字" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="未稅金額" field="UntaxedAmount" :item-render="{}" span="12">
        <template #default="{ data }">
          <vxe-input v-model="data.UntaxedAmount" type='number' placeholder="請輸入數字" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="稅金" field="TaxAmount" :item-render="{}" span="12">
        <template #default="{ data }">
          <vxe-input v-model="data.TaxAmount" type='number' placeholder="請輸入數字" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="金額" field="Amount" :item-render="{}" span="12">
        <template #default="{ data }">
          <vxe-input v-model="data.Amount" type='number' placeholder="請輸入數字" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <vxe-form-item title="交易來源IP" field="RemoteIp" :item-render="{}" span="12">
        <template #default="{ data }">
          <vxe-input v-model="data.RemoteIp" type='text' placeholder="請輸入文字" clearable></vxe-input>
        </template>
      </vxe-form-item>
      <!-- <vxe-form-item title="確認者" field="Confirmor.Name" :item-render="{}" span="12">
        <template #default="{ data }">
          <vxe-input v-model="data.Confirmor.Name" type='text' placeholder="請輸入文字" clearable></vxe-input>
        </template>
      </vxe-form-item> -->
      <vxe-form-item title="狀態" field="Status" :item-render="{}" span="12">
        <template #default="{ data }">
          <vxe-select v-model="data.Status" placeholder="請選擇支付狀態" clearable>
            <vxe-option v-for="(item) in Object.values(model?.enums?.PaymentStatus).filter(e => e.Value == 0 || e.Value == 11 ||e.Value == 24)" :key="item.Value" :value="item.Value" :label="item.Name"></vxe-option>
          </vxe-select>
        </template>
      </vxe-form-item>
      <vxe-form-item class-name="text-left" :item-render="{}" span="24">
          <template #default>
            <vxe-button class="" type="submit" status="primary" content="確認">
            </vxe-button>
          </template>
      </vxe-form-item>
    </vxe-form>
  </fieldset>
  <!-- <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="search" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword" placeholder="請輸入關鍵字" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <div class="flex justify-end mr-2">
            <button v-if="grid && !grid.isEditing" class="button text-white bg-green-500 shadow-md flex items-center mb-2 mr-2" @click="send">
              發行優惠
            </button>
            <button v-if="grid && !grid.isEditing" class="button text-white bg-theme-1 shadow-md flex items-center mb-2 mr-2" @click="recordModal.show">
              發行紀錄
            </button>
            <button v-if="grid && !grid.isEditing" class="button text-white bg-red-500 shadow-md flex items-center mb-2" @click="deleteAll">
              清除列表
            </button>
        </div>
  </div> -->
  <Grid ref="grid" v-bind="gridOptions"
    @edit="onGridEdit"
  >
    <template #modal="{ row, submit, reset }">
      <vxe-form
        class="ml-3"
        ref="sessionForm"
        v-bind="formOptions"
        :data="row"
        @reset="reset"
        @submit="submit">
      </vxe-form>
    </template>
    <template #modal-footer>
      <vxe-button type="submit" status="primary" content="確認" @click="close"></vxe-button>
    </template>
  </Grid>

</template>
<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType, onMounted } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import { watch } from 'vue-demi';
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  props: {
    sid: Number,
    modelValue: Boolean,
    promises: Object as PropType<{
      /** 非同步查詢 */
      query?: (params: {
        page: number;
        pageSize: number;
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<{ data: any[]; totalCount: number }>;
      /** 查詢所有資料 */
      queryAll?: (params?: {
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<any[]>;
      /** 儲存已變更資料 */
      save?: (params: {
        insertRows?: any[];
        updateRows?: any[];
        deleteRows?: any[];
      }) => Promise<void>;
    }>
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;

    const table = reactive({
      data: {} as any,
      isLoading: false,
      isEditing: false,
      formData: {} as any,
      selectedRow: null as any | null,
      async save() {
        await model?.dispatch('payment/update', table.formData).then((data) => {
          emit("close");
        }).catch((error) =>{
          console.log(error)
          CloudFun.send("error", {
            subject: "執行失敗",
            content: `${error}`,
          });
        })
      },
    })

    const grid = ref<any>({});
    const gridOptions: GridOptions = {
      title: '繳費紀錄',
      canCreate: false,
      canUpdate: false,
      canDelete: false,
      multiselect: false,
      showFooter: true,
      columns: [
        { field: 'CreatedTime', title: '建立時間', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd HH:mm:ss') : '' },
        { field: 'Type', title: '類型', showHeaderOverflow: true, showOverflow: true, sortable: true,
          formatter: ({ cellValue }) => {
            const item = model?.enums && Object.values(model?.enums.PaymentActionType).find((e) => e.Value === cellValue);
            return item ? item.Name : "";
          }
        },
        { field: 'Success', title: '是否成功', showHeaderOverflow: true, showOverflow: true, sortable: true, align: 'center', formatter: ({ cellValue }) => cellValue ? '是' : '否' },
        { field: 'Time', title: '建立時間', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd HH:mm:ss') : '' },
        { field: 'Number', title: '交易訂單編號', showOverflow: true, sortable: true, resizable: false },
      ],
      promises: {
        query: model ? (params) => {
          if(table.formData.Id) {
            if (params.condition) params.condition.and('PaymentId', Operator.Equal, table.formData.Id)
            else params.condition = new Condition('PaymentId', Operator.Equal, table.formData.Id)
            if (params?.sortings?.length === 0) {
              params.sortings.push({ column: 'CreatedTime', order: 1 });
            }
            return model.dispatch('paymentRecord/query', params)
          } else return new Promise((resolve, reject) => resolve({ data:[], totalCount: 0 }));
        } : undefined,
        queryAll: model ? () => model.dispatch('paymentRecord/query') : undefined,
        save: model ? (params) => model.dispatch('paymentRecord/save', params) : undefined
      },
      modalConfig: { width: "50%", height: "550", showFooter: true },
    }


    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        {
          field: 'CreatedTime',
          title: '建立時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd HH:mm:ss', disabled: 'true', placeholder: '請輸入日期', clearable: true } }          
        },
        { field: 'Number', title: '編號', span: 12, itemRender: { name: '$input', props: { disabled: 'true' } } },
        {
          field: 'Type',
          title: '類型',
          span: 12,
          itemRender: { name: "$select", props: { disabled: 'true' }, options: model ? Object.values(model.enums.PaymentActionType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        // {
        //   field: 'RequestType',
        //   title: '請求型別',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', disabled: true }, attrs: { type: 'text' } }          
        // },
        {
          field: 'ResponseType',
          title: '回應型別',
          span: 12,
          itemRender: { name: '$input', props: { type: 'text', disabled: 'true', placeholder: '請輸入文字' } }          
        },
        {
          field: 'Success',
          title: '是否成功',
          span: 12,
          itemRender: { name: '$select', props: { disabled: 'true' }, options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'Time',
          title: '時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd HH:mm:ss', disabled: 'true', placeholder: '請輸入日期', clearable: true } }          
        },
        // { field: 'Request', title: '請求資料', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '', disabled: 'true' } } },
        { field: 'Response', title: '回應資料', span: 24, itemRender: { name: '$textarea', props: { autosize: { minRows: 5, maxRows: 8 }, placeholder: '', disabled: 'true' } } },
      ],
      rules:{
        Name: [{ type: 'string', required: true }],
        Date: [{ type: 'string', required: true }],
      }
    }

    const initData = (row: any, callback: any) => {
      callback();
    }

    const form = ref<any>();
    const list = ref<any[]>([]);

    const onGridEdit = (row: any, callback: any) => {
      callback()
    }

    watch(() => props.modelValue, async(value: any) => {
      table.formData = {};
      if(value) {
        table.formData = await model?.dispatch("payment/findByAttend", props.sid);
        console.log(table.formData)
        if(table.formData.Id) grid.value.refresh();
      }
    }, { immediate: true });

    const close = () => {
      grid.value.isModalPopup = false;
    }
    
    return {
      grid,
      initData,
      gridOptions,
      formOptions,
      list,
      form,
      table,
      onGridEdit,
      model,
      close,
    }
  },
  methods: {
    refresh() {
      (this.$refs.grid as any).refresh();
    },    
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});
</script>

<style scoped>
.custom-vue-selector :deep(.vue-tags-input.ti-disabled) {
  background-color: #f3f3f3;
}
.vxe-form--item.is--error .custom-vue-selector :deep(.vue-tags-input .ti-input) {
  border-color: #f56c6c !important;
}
</style>
