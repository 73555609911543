<template>
  <div v-if="grid" class="flex flex-col mb-1 sm:flex-row sm:items-end xl:items-start">
    <div class="xl:flex sm:mr-auto">
      <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
        <input type="search" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword" placeholder="請輸入關鍵字" />
      </div>
      <div class="mt-2 flex xl:mt-0">
        <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white m-2" @click="grid.refresh">
          搜尋
        </button>
        <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white m-2" @click="exportExcel">
          匯出
        </button>
        <button type="button" class="button w-full sm:w-32 bg-theme-1 text-white m-2" @click="numberCreate">
          產生報到編號
        </button>
        <button type="button" class="button w-full sm:w-32 bg-theme-1 text-white m-2" @click="sendMail">
          產生報到通知
        </button>
        <!-- <button type="button" class="button w-full sm:w-32 bg-theme-1 text-white m-2" @click="sendEventMail(81)">
          簡報下載通知
        </button>
        <button v-if="haveQnr" type="button" class="button w-full sm:w-44 bg-theme-1 text-white m-2" @click="sendEventMail(82)">
          簡報下載通知(問卷)
        </button> -->
        <button v-if="haveQnr" type="button" class="button w-full sm:w-32 bg-theme-1 text-white m-2" @click="qnrReportModal.show">
          問卷統計
        </button>
      </div>
    </div>
  </div>
  <Grid ref="grid" v-bind="gridOptions"
    @removeSelectedRows="hideBatchDropDown()"
    @edit="onGridEdit"
  >
    <template #numberCreate="{ row }">
      <vxe-button type="button" content="產生" :disabled="row.Canceled" @click="singleNumberCreate(row.Id)"></vxe-button>
    </template>
    <template #send="{ row }">
      <vxe-button type="button" content="通知" :disabled="row.Canceled" @click="singleSend(row.Id)"></vxe-button>
    </template>
    <template #sendEvent="{ row }">
      <vxe-button type="button" content="通知" :disabled="!(!row.Canceled && row.Attended && row.Downloadable)" @click="singleSendEvent(row.Id, 81)"></vxe-button>
    </template>
    <template #sendEventQnr="{ row }">
      <vxe-button type="button" content="通知" :disabled="(!(!row.Canceled && row.Attended)) || !haveQnr" @click="singleSendEvent(row.Id, 82)"></vxe-button>
    </template>
    <template #Attended="{ row }">
      <vxe-switch v-model="row.Attended" open-label="是" close-label="否" @change="updateRow(row)"></vxe-switch>
    </template>
    <template #Canceled="{ row }">
      <vxe-switch v-model="row.Canceled" open-label="是" close-label="否" @change="updateRow(row)"></vxe-switch>
    </template>
    <template #Downloadable="{ row }">
      <vxe-switch v-model="row.Downloadable" open-label="是" close-label="否" @change="updateRow(row)"></vxe-switch>
    </template>
    <template #Qnr="{ row }">
      <vxe-button type="button" content="查看" :disabled="!haveQnr" @click="qnrModal.show(row)"></vxe-button>
    </template>
    <template #modal="{ row, submit, reset }">
      <vxe-form
        class="ml-3"
        ref="sessionForm"
        v-bind="formOptions"
        :data="row"
        @reset="reset"
        @submit="submit">
        <template #info="{ data }">
          <select-box v-bind="infoSelectorOptions" v-if="data && data.AttendMember" class="custom-vue-selector" v-model="data.AttendMember.ActivityInfoChannels" :readonly="true" />
        </template>
      </vxe-form>
    </template>
    <template #modal-footer>
      <vxe-button type="submit" status="primary" content="確認" @click="close"></vxe-button>
      <!--  -->
    </template>
  </Grid>
  <vxe-modal v-model="qnrModal.visible" width="80%" title="問卷回覆管理" height="700" show-zoom="true">
    <QnrTable ref="qnrGrid" v-bind="qnrModal.gridOptions" :sid="qnrModal.selectedRow.AttendMemberId" />
  </vxe-modal>
  <vxe-modal v-model="qnrReportModal.visible" width="80%" title="問卷統計" height="700" show-zoom="true">
    <QnrReportTable ref="qnrReportGrid" :visible="qnrReportModal.visible" :id="qnrReportModal.selectedRowId" />
  </vxe-modal>
</template>
<script lang="ts">
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import QnrTable from "@/components/activity/QnrTable.vue";
import QnrReportTable from "@/components/activity/QnrReportTable.vue";
import { watch } from 'vue'
import { stubTrue } from 'lodash';

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    QnrTable,
    QnrReportTable,
  },
  props: {
    sid: Number,
    haveQnr: Boolean,
    promises: Object as PropType<{
      /** 非同步查詢 */
      query?: (params: {
        page: number;
        pageSize: number;
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<{ data: any[]; totalCount: number }>;
      /** 查詢所有資料 */
      queryAll?: (params?: {
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<any[]>;
      /** 儲存已變更資料 */
      save?: (params: {
        insertRows?: any[];
        updateRows?: any[];
        deleteRows?: any[];
      }) => Promise<void>;
    }>
  },
  setup(props) {
    const model = CloudFun.current?.model

    const grid = ref<any>();

    const gridOptions: GridOptions = {
      title: '場次報名資料',
      canCreate: false,
      canUpdate: false,
      canDelete: false,
      multiselect: false,
      showFooter: true,
      toolbarConfig: {
        refresh: true,
      },
      columns: [
        //{ field: 'CreatedTime', title: '建立時間', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : '' },
        //{ field: 'CouponSetId', title: '折扣碼設定識別碼', showOverflow: true, sortable: true, resizable: false },
        //{ field: 'CategoryId', title: '折扣類型識別碼', showOverflow: true, sortable: true, resizable: false },
        // { field: 'Number', title: '報名編號', showOverflow: true, sortable: true, resizable: true },
        { field: 'AttendNumber', title: '報到編號', showOverflow: true, sortable: true, resizable: true,
          formatter: ({ cellValue }) => {
            return cellValue ? cellValue.substring(cellValue.length - 3, cellValue.length) : cellValue
          }
        },
        { field: 'AttendMember.CompanyName', title: '公司名稱', showOverflow: true, sortable: true, resizable: true,                  
        },
        { field: 'AttendMember.Name', title: '姓名', showOverflow: true, sortable: true, resizable: true },
        { field: 'AttendMember.Email', title: 'Email', showOverflow: true, sortable: true, resizable: true },
        {
          title: '是否出席',
          width: '100',
          slots: { default: "Attended" }, align: 'center'
        },
        {
          title: '是否取消',
          width: '100',
          slots: { default: "Canceled" }, align: 'center'
        },
        // {
        //   title: '下載簡報',
        //   width: '100',
        //   slots: { default: "Downloadable" }, align: 'center'
        // },
        // {
        //   title: '產生報到編號',
        //   width: '120',
        //   slots: { default: "numberCreate" }, align: 'center'
        // },
        {
          title: '產生報到通知',
          width: '120',
          slots: { default: "send" }, align: 'center'
        },
        // {
        //   title: '簡報下載通知',
        //   width: '120',
        //   slots: { default: "sendEvent" }, align: 'center'
        // },
        // {
        //   title: '簡報下載通知(問卷)',
        //   width: '120',
        //   slots: { default: "sendEventQnr" }, align: 'center'
        // },
        {
          title: '問卷資料',
          width: '100',
          slots: { default: "Qnr" }, align: 'center'
        },
        // { field: 'Attended', title: '是否出席', showOverflow: true, sortable: true, resizable: false },
        // { field: 'Canceled', title: '是否取消', showOverflow: true, sortable: true, resizable: false },
        // { field: 'AttendMember.Downloadable', title: '下載簡報', showOverflow: true, sortable: true, resizable: false },
      ],
      promises: { query: props.promises?.query, queryAll: props.promises?.queryAll, save: props.promises?.save },
      modalConfig: { width: "60%", height: "600", showFooter: true },
    }

    // watch(() => props.haveQnr, async (value: any) => {
    //   console.log(value)
    //   gridOptions.columns[9].visible = value;
    //   gridOptions.columns[10].visible = value;
    //   if(grid.value && grid.value.reloadColumn) await grid.value.reloadColumn(gridOptions.columns);
    // }, { immediate: true });

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'Number',
          title: '報名編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        },
        {
          field: 'AttendNumber',
          title: '報到編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        },
        {
          field: 'AttendMember.Name',
          title: '姓名',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'AttendMember.EnName',
        //   title: '英文姓名',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        // },
        {
          field: 'AttendMember.Sex',
          title: '性別',
          span: 12,
          itemRender: { name: "$select", props: { placeholder: '', disabled: 'true' }, options: model ? Object.values(model.enums.Sex).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'AttendMember.Email',
          title: 'Email',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        },
        {
          field: 'AttendMember.Phone',
          title: '連絡電話',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'AttendMember.Extention',
        //   title: '分機',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        // },
        {
          field: 'AttendMember.PostCode',
          title: '郵遞區號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        },
        {
          field: 'AttendMember.Address',
          title: '通訊地址',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        },
        {
          field: 'AttendMember.CompanyName',
          title: '公司名稱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'AttendMember.EnCompanyName',
        //   title: '英文公司名稱',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'AttendMember.Department',
        //   title: '部門',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'AttendMember.EnDepartment',
        //   title: '部門英文',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        // },
        {
          field: 'AttendMember.JobTitle',
          title: '職稱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'Survey',
        //   title: '調查表資訊',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        // },                  
        // {
        //   field: 'Coupon.Number',
        //   title: '折扣編號',
        //   span: 8,
        //   itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'Coupon.Code',
        //   title: '折扣資料',
        //   span: 8,
        //   itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'Coupon.Discount',
        //   title: '折扣',
        //   span: 8,
        //   itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'CouponSet.Category.Name',
        //   title: '優惠名稱',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'CouponSet.Discount',
        //   title: '優惠',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        // },
        {
          field: 'MealType',
          title: '餐食類型',
          span: 12,
          itemRender: { name: "$select", props: { placeholder: '', disabled: 'true' }, options: model ? Object.values(model.enums.MealType).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'Canceled',
          title: '已取消',
          span: 12,
          itemRender: { name: '$select', props: { disabled: 'true' }, options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'Attended',
          title: '已出席',
          span: 12,
          itemRender: { name: '$select', props: { disabled: 'true' }, options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'SalePrice',
          title: '活動定價',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        },
        {
          field: 'DiscountAmount',
          title: '扣除金額',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        },
        {
          field: 'Amount',
          title: '金額',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'Downloadable',
        //   title: '可下載簡報檔案',
        //   span: 12,
        //   itemRender: { name: '$select', props: { disabled: 'true' }, options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        // },  
        // {
        //   field: 'ActivityInfoChannels',
        //   title: '得知活動管道',
        //   span: 24,
        //   slots: { default: "info" }
        // },
        // {
        //   field: 'AttendMember.OtherText',
        //   title: '其他說明',
        //   span: 24,
        //   itemRender: { name: '$input', props: { placeholder: '', disabled: 'true' }, attrs: { type: 'text' } }
        // },
      ],
      rules:{
      }
    }

    const close = () => {
      grid.value.isModalPopup = false;
    }

    const infoSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Name",
      valueField: "Id",
      addIfNotExists: true,
      columns: [
        { field: "Name", title: "名稱", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      multiselect: true,
      showHeader: true,
      promises: {
        find: (value) => new Promise((resolve, reject) => resolve(Object.values(model!.enums.ActivityInfoChannel).map(e => { return { Name: e.Name, Id: e.Value } }).find(e => e.Id == value))), // eslint-disable-line
        query: (params) => {
          //if (params.condition) params.condition.and('Published', Operator.Equal, true).and('Type', Operator.Equal, 1)
          //else params.condition = new Condition('Published', Operator.Equal, true).and('Type', Operator.Equal, 1)
          return new Promise((resolve, reject) => resolve({ data: Object.values(model!.enums.ActivityInfoChannel).map(e => { return { Name: e.Name, Id: e.Value } }), totalCount: 1 }));
        },
      },
    };

    const updateRow = async(row: any) => {
      if(row && row.Id) {
        await model!.dispatch('attendDetail/update', row).then((data) => {
          grid.value.refresh();
        }).catch((error) =>{
          console.log(error)
          CloudFun.send("error", {
            subject: "執行失敗",
            content: error,
          });
        });
      }
    }

    const exportExcel = async() => {
      if(props.sid){
        window.open(`${process.env.VUE_APP_BACKEND_URL}/api/AttendDetail/ExportExcel/${props.sid}`)
        // model!.dispatch('attendDetail/exportExcel', props.sid).then((data) => {
        //   var blob = new Blob([data.data], {type: "application/octet-stream"});
        //   console.log(blob)
        //   const url = window.URL.createObjectURL(blob);
        //   const tag = document.createElement('a');
        //   tag.href = url;
        //   tag.setAttribute('download', data.name);
        //   document.body.appendChild(tag);
        //   tag.click();
        // }).catch((error) =>{
        //   console.log(error)
        //   CloudFun.send("error", {
        //     subject: "執行失敗",
        //     content: error,
        //   });
        // });
      } else {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: "查無場次資料",
        });
      }
    }

    const attendNumberCreate = async() => { 
      await model?.dispatch('attendDetail/numberCreate', props.sid).then(() => {            
      }).catch((error) =>{
        console.log(error)
        CloudFun.send("error", {
          subject: "執行失敗",
          content: "產生編號失敗:"+ error,
        });
      });
    }

    const attendNumberClear = async() => { 
      await model?.dispatch('attendDetail/numberClear', props.sid).then(() => {            
      }).catch((error) =>{
        console.log(error)
        CloudFun.send("error", {
          subject: "執行失敗",
          content: "編號清除失敗:"+ error,
          });
      });
    }

    const numberCreate = async() => {
      if(props.sid){
        if(confirm('確定要產生所有報到編號嗎?')) {
          try {
            await attendNumberClear();
            await attendNumberCreate();
            CloudFun.send("info", {
              subject: "執行成功",
              content: "已產生完成",
            });
            grid.value.refresh();
          } catch (error) {
            console.log(error)
          }
        }
      } else {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: "查無場次資料",
        });
      }
    }

    const singleNumberCreate = async(id: any) => {
      if(props.sid){
        if(confirm('確定要產生報到編號嗎?')) {
          await model?.dispatch('attendDetail/singleNumberCreate', id).then(() => {
            CloudFun.send("info", {
              subject: "執行成功",
              content: "已產生完成",
            });
            grid.value.refresh();
          }).catch((error) =>{
            console.log(error)
            CloudFun.send("error", {
              subject: "執行失敗",
              content: error,
            });
          });
        }
      } else {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: "查無場次資料",
        });
      }
    }

    const sendMail = async() => {
      if(props.sid){
        if(confirm('確定要寄出報到通知嗎? 不產生信件與報到編號者，請將取消設定為\"是\"')) {
          await model?.dispatch('attendDetail/send', props.sid).then(() => {
            CloudFun.send("info", {
              subject: "執行成功",
              content: "已發行完成",
            });
            grid.value.refresh();
          }).catch((error) =>{
            console.log(error)
            CloudFun.send("error", {
              subject: "執行失敗",
              content: error,
            });
          });
        }
      } else {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: "查無場次資料",
        });
      }
    }

    const singleSend = async(id: any) => {
      if(props.sid){
        if(confirm('確定要寄出報到通知嗎?')) {
          await model?.dispatch('attendDetail/singleSend', id).then(() => {
            CloudFun.send("info", {
              subject: "執行成功",
              content: "已發行完成",
            });
            grid.value.refresh();
          }).catch((error) =>{
            console.log(error)
            CloudFun.send("error", {
              subject: "執行失敗",
              content: error,
            });
          });
        }
      } else {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: "查無場次資料",
        });
      }
    }

    const sendEventMail = async(type: any) => {
      if(props.sid){
        var msg = type == 81 ? '確定要寄出下載簡報通知嗎? 僅會發送下載簡報及出席為\"是\"者' : '確定要寄出下載簡報通知嗎? 僅會發送出席為\"是\"者';
        if(confirm(msg)) {
          await model?.dispatch('attendDetail/sendEvent', { id: props.sid, type: type }).then(() => {
            CloudFun.send("info", {
              subject: "執行成功",
              content: "已發行完成",
            });
            grid.value.refresh();
          }).catch((error) =>{
            console.log(error)
            CloudFun.send("error", {
              subject: "執行失敗",
              content: error,
            });
          });
        }
      } else {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: "查無場次資料",
        });
      }
    }

    const singleSendEvent = async(id: any, type: any) => {
      if(id){
        if(confirm('確定要寄出下載簡報通知嗎?')) {
          await model?.dispatch('attendDetail/singleSendEvent', { id: id, type: type }).then(() => {
            CloudFun.send("info", {
              subject: "執行成功",
              content: "已發行完成",
            });
            grid.value.refresh();
          }).catch((error) =>{
            console.log(error)
            CloudFun.send("error", {
              subject: "執行失敗",
              content: error,
            });
          });
        }
      } else {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: "查無場次資料",
        });
      }
    }

    const qnrReportGrid = ref<any>()
    const qnrReportModal = reactive({
      visible: false,
      selectedRow: {} as any,
      selectedRowId: 0 as any,
      details: [] as any,
      async show(row: any) {
        qnrReportModal.visible = true;
        qnrReportModal.selectedRowId = props.sid;
        //if (qnrReportGrid.value) qnrReportGrid.value.refresh();
      }
    })

    const qnrGrid = ref<any>()
    const qnrModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition()
              .and("AttendDetailId", Operator.Equal, qnrModal.selectedRow.Id.toString())
              .and(params.condition!);
            if (!params.sortings || params.sortings?.length === 0)
              params.sortings = [{ column: "Question.Ordinal", order: 0 },{ column: "Question.Type", order: 1 },{ column: "CreatedTime", order: 1 }];
            return model.dispatch("attendMemberQuestion/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('attendMemberQuestion/query') : undefined,
        }
      } as GridOptions,
      show(row: any) {
        qnrModal.visible = true;
        qnrModal.selectedRow = row;
        // console.log(row)
        if (qnrGrid.value) qnrGrid.value.refresh();
      }
    })

    return {
      grid,
      gridOptions,
      formOptions,
      close,
      infoSelectorOptions,
      updateRow,
      exportExcel,
      sendMail,
      singleSend,
      sendEventMail,
      singleSendEvent,
      numberCreate,
      singleNumberCreate,
      qnrModal,
      qnrGrid,
      qnrReportGrid,
      qnrReportModal,
    }
  },
  methods: {
    refresh() {
      (this.$refs.grid as any).keyword = null;
      (this.$refs.grid as any).refresh();
    },
    async onGridEdit (row: any, callback: any) {
      const entity = row.Id ? await this.$model.dispatch('attendDetail/find', row.Id) : undefined
      Object.assign(row, entity)
      // const statuses = (Object.values(this.$model.enums.DocumentStatus) as any[]);
      // row.BeforeStatusName = statuses.find(e => e.Value === row.BeforeStatus).Name;
      // row.AfterStatusName = statuses.find(e => e.Value === row.AfterStatus).Name;
      //   if (entity) {
      //     entity.Xml.Time = new Date(entity.Xml.Time)
      //   }
      callback()
    },
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});
</script>

<style scoped>
.custom-vue-selector :deep(.vue-tags-input.ti-disabled) {
  background-color: #f3f3f3;
}
.vxe-form--item.is--error .custom-vue-selector :deep(.vue-tags-input .ti-input) {
  border-color: #f56c6c !important;
}
</style>