<template>
  <fieldset class="border rounded my-2 ml-4 p-2">
    <div v-if="!readonly" class="flex items-center mx-1 mb-2">
      活動機關
      <button type="button" class="ml-auto" @click="table.insert">
        <FontAwesome icon="plus" class="w-5 h-5 text-yellow-500" />
      </button>
    </div>
    <vxe-table ref="grid" :data="table.data" v-bind="gridOptions.props">
      <vxe-column field="Name" title="機關名稱">
        <template #default="{ row }">
          <span v-if="row.Unit">{{ row.Unit.Name }}</span>
        </template>
      </vxe-column>
      <vxe-column field="Type" title="類型" :formatter="({ cellValue }) => Object.values($enums.UnitType).find(e => e.Value === cellValue)?.Name">
      </vxe-column>
      <vxe-column field="Ordinal" title="排序" sortable>
      </vxe-column>
      <vxe-column v-if="!readonly" title="操作" width="200">
        <template #default="{ row }">
          <div class="flex justify-content">
            <vxe-button @click="table.update(row)">編輯</vxe-button>
            <vxe-button @click="table.delete(row)">刪除</vxe-button>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
    <vxe-modal v-model="table.isEditing" :title="table.selectedRow ? '編輯機關' : '新增機關'" width="50%" min-width="350"
      :loading="table.isLoading" resize destroy-on-close showFooter show-zoom="true">
      <template #default>
        <vxe-form ref="form" :data="table.formData" :rules="table.rules" titleAlign="right" @submit="table.save">
          <vxe-form-item title="機關名稱" field="UnitId" :item-render="{}" span="24">
            <template #default="{ data }">
              <select-box v-bind="unitSelectorOptions" v-model="data.UnitId" />
            </template>
          </vxe-form-item>
          <vxe-form-item title="類型" field="Type" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-select v-model="data.Type" placeholder="請選擇類型" clearable>
                <vxe-option v-for="(item) in Object.values($enums.UnitType)" :key="'unitType-' + item.Value" :value="item.Value" :label="item.Name"></vxe-option>
              </vxe-select>
            </template>
          </vxe-form-item>
          <vxe-form-item title="排序" field="Ordinal" :item-render="{}" span="12">
            <template #default="{ data }">
              <vxe-input type="number" v-model="data.Ordinal" placeholder="請輸入排序" clearable></vxe-input>
            </template>
          </vxe-form-item>
          <!-- <vxe-form-item title="外部來源" field="Source" :item-render="{}" span="24">
            <template #default="{ data }">
              <vxe-textarea v-model="data.Source" placeholder="請輸入外部來源" clearable></vxe-textarea>
             </template>
          </vxe-form-item> -->
        </vxe-form>
      </template>
      <template #footer>
        <vxe-button type="button" status="primary" content="確認"
          @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })">
        </vxe-button>
      </template>
    </vxe-modal>
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps } from "vxe-table";

interface Unit {
  UnitId?: string;
  Unit?: { Id: string, Name: string };
  Ordinal: number;
  Type: number;
}

export default defineComponent({
  name: "DocumentUnitTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<Unit[]>,
      default: () => []
    },
    aid: Number,
    readonly: Boolean
  },
  setup(props) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          defaultSort: { field: 'Ordinal', order: 'asc' }
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as Unit[],
      isLoading: false,
      isEditing: false,
      formData: { Ordinal: 0, Type: 0 } as Unit,
      selectedRow: null as Unit | null,
      insert() {
        table.formData = { Ordinal: 0, Type: 0, };
        table.selectedRow = null;
        table.isEditing = true;
      },
      update(row: Unit) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: Unit) {
        grid.value.remove(row);
      },
      async save() {
        table.isLoading = true;
        console.log(table.formData);
        if (table.selectedRow) {
          Object.assign(table.selectedRow, table.formData)
        } else {
          if (table.formData.UnitId) {
            const temp = await model?.dispatch("unit/find", table.formData.UnitId)
            table.formData.Unit = { Id: temp.Id, Name: temp.Name };
            //table.formData.AgendaId = props.aid!;
          }

          await grid.value.insert(table.formData);
        }
        grid.value.sort("Ordinal", 'asc');
        table.isEditing = false;
        table.isLoading = false;
      },
      rules: {
        UnitId: [{ required: true }],
        Type: [{ required: true }],
        Ordinal: [{ required: true }],
      }
    })

    watch(() => props.data, async(value) => {
      const UnitIds = value.filter(e => e.UnitId).map(e => e.UnitId);
      const Units: Unit['Unit'][] = await model?.dispatch("unit/query", { condition: new Condition("Id", Operator.In, UnitIds) });
      table.data = value.map(e => {
        if (e.UnitId) {
          const Unit = Units.find(a => a && a.Id === e.UnitId)
          if(Unit) e.Unit = { Id: Unit!.Id, Name: Unit!.Name };
        } else {
        }
        return e;
      }).sort((a, b) => a.Ordinal - b.Ordinal);
    }, { immediate: true });

    const form = ref<any>();

    const unitSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => { 
          value.condition = new Condition().and("Published", Operator.Equal, true)
          return model!.dispatch("unit/find", value); // eslint-disable-line
        },
        query: params => model!.dispatch("unit/query", params) // eslint-disable-line
      }
    };

    return {
      table,
      grid,
      gridOptions,
      form,
      unitSelectorOptions,
    }
  },
  methods: {
    getData() {
      // 避免 Unit 帶過多關聯使的API維護Mapping
      return this.grid.getTableData().fullData.map((e: Unit) => {
        delete e.Unit;
        return e;
      });
    }
  }
})
</script>
