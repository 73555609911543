
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps } from "vxe-table";

interface Unit {
  UnitId?: string;
  Unit?: { Id: string, Name: string };
  Ordinal: number;
  Type: number;
}

export default defineComponent({
  name: "DocumentUnitTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<Unit[]>,
      default: () => []
    },
    aid: Number,
    readonly: Boolean
  },
  setup(props) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          defaultSort: { field: 'Ordinal', order: 'asc' }
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as Unit[],
      isLoading: false,
      isEditing: false,
      formData: { Ordinal: 0, Type: 0 } as Unit,
      selectedRow: null as Unit | null,
      insert() {
        table.formData = { Ordinal: 0, Type: 0, };
        table.selectedRow = null;
        table.isEditing = true;
      },
      update(row: Unit) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: Unit) {
        grid.value.remove(row);
      },
      async save() {
        table.isLoading = true;
        console.log(table.formData);
        if (table.selectedRow) {
          Object.assign(table.selectedRow, table.formData)
        } else {
          if (table.formData.UnitId) {
            const temp = await model?.dispatch("unit/find", table.formData.UnitId)
            table.formData.Unit = { Id: temp.Id, Name: temp.Name };
            //table.formData.AgendaId = props.aid!;
          }

          await grid.value.insert(table.formData);
        }
        grid.value.sort("Ordinal", 'asc');
        table.isEditing = false;
        table.isLoading = false;
      },
      rules: {
        UnitId: [{ required: true }],
        Type: [{ required: true }],
        Ordinal: [{ required: true }],
      }
    })

    watch(() => props.data, async(value) => {
      const UnitIds = value.filter(e => e.UnitId).map(e => e.UnitId);
      const Units: Unit['Unit'][] = await model?.dispatch("unit/query", { condition: new Condition("Id", Operator.In, UnitIds) });
      table.data = value.map(e => {
        if (e.UnitId) {
          const Unit = Units.find(a => a && a.Id === e.UnitId)
          if(Unit) e.Unit = { Id: Unit!.Id, Name: Unit!.Name };
        } else {
        }
        return e;
      }).sort((a, b) => a.Ordinal - b.Ordinal);
    }, { immediate: true });

    const form = ref<any>();

    const unitSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => { 
          value.condition = new Condition().and("Published", Operator.Equal, true)
          return model!.dispatch("unit/find", value); // eslint-disable-line
        },
        query: params => model!.dispatch("unit/query", params) // eslint-disable-line
      }
    };

    return {
      table,
      grid,
      gridOptions,
      form,
      unitSelectorOptions,
    }
  },
  methods: {
    getData() {
      // 避免 Unit 帶過多關聯使的API維護Mapping
      return this.grid.getTableData().fullData.map((e: Unit) => {
        delete e.Unit;
        return e;
      });
    }
  }
})
