<template>
  <fieldset class="border rounded m-2 px-2 pt-2">
    <div class="flex items-center mx-1 mb-2">
      選項
    </div>
    <!-- <div v-if="!readonly" class="flex items-center mx-1 mb-2">
      <button type="button" class="ml-auto" @click="table.insert">
        <FontAwesome icon="plus" class="w-5 h-5 text-yellow-500" />
      </button>
    </div> -->
    <vxe-form class="" ref="surveyDetailForm" :data="table.formData" :rules="table.rules" titleAlign="right" @submit="table.save">
        <vxe-form-item title="選項名稱" field="Option" :item-render="{}" span="10">
          <template #default="{ data }">
            <vxe-input v-model="data.Option" type='text' placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item title="補充說明" field="Description" :item-render="{}" span="10">
          <template #default="{ data }">
            <vxe-input v-model="data.Description" type='text' placeholder="請輸入文字" clearable></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item :item-render="{}" span="4">
          <template #default>
            <vxe-button type="submit" status="primary" content="確認">
            </vxe-button>
          </template>
        </vxe-form-item>
    </vxe-form>
    <vxe-table class="mb-2" ref="grid" :data="table.data" v-bind="gridOptions.props">
      <vxe-column field="Option" title="選項名稱" sortable>
      </vxe-column>
      <vxe-column field="Description" title="補充說明" sortable>
      </vxe-column>
      <vxe-column v-if="!readonly" title="操作" width="150">
        <template #default="{ row }">
          <div class="flex justify-content">
            <!-- <vxe-button @click="table.update(row)">編輯</vxe-button> -->
            <vxe-button @click="table.delete(row.Id)">刪除</vxe-button>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
  </fieldset>
</template>
<script lang="ts">
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps } from "vxe-table";

interface Speaker {
  Name?: string;
  SpeakerId?: string;
  Speaker?: { Id: string, Name: string, Photo: any };
  Type: number;
  Ordinal: number;
  AgendaId: number;
}

export default defineComponent({
  name: "SurveyDetailTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    readonly: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          defaultSort: {  } //field: 'Ordinal', order: 'asc'
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: { } as any,
      selectedRow: null as any | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: any) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      async delete(rowId: any) {
        try {
          await model?.dispatch("surveyDetail/delete", rowId)
          emit('refresh');
        } catch (error) {
          console.error(error);
        }
      },
      async save() {
        if (props.aid) {
          try {
            table.formData.SurveyId = props.aid;
            await model?.dispatch("surveyDetail/insert", table.formData)         
            emit('refresh');
          } catch (error) {
            console.error(error);
          } 
        } else {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "無法取得調查表Id",
          });
        }
      },
      rules: {
        Option: [{ required: true }],
      }
    })

    watch(() => props.data, async(value) => {
      table.formData = { } as any;
      table.data = value
    }, { immediate: true });

    const surveyDetailForm = ref<any>();

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Company",
          title: "公司名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("speaker/find", value), // eslint-disable-line
        query: params => model!.dispatch("speaker/query", params) // eslint-disable-line
      }
    };
    return {
      table,
      grid,
      gridOptions,
      surveyDetailForm,
      userSelectorOptions
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
</script>
